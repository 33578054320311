import React, { useEffect, useState } from 'react';

import OnboardingModal from './OnboardingModal';
import { useHistory, useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import { completeAuthWithToken, getCurrentUser } from '../../data/users/slice';

export function Onboarding(props) {
  var parsedParams = new URLSearchParams(useLocation().search);
  const [signupType] = useState(parsedParams.get('signupType'));
  const [refreshUser] = useState(parsedParams.get('refreshUser'));
  const [plan] = useState(parsedParams.get('plan'));
  const [open, setOpen] = useState(parsedParams.has('signupType'));
  const history = useHistory();
  const dispatch = useDispatch();
  
  useEffect(() => {
    if (signupType && parsedParams.get('token')) {
      // This is a signup, so let's look for the Stytch token to complete the flow
      const token = parsedParams.get('token');
      parsedParams.delete('signupType');
      parsedParams.delete('token');
      history.replace({
        search: parsedParams.toString(),
      });
      dispatch(completeAuthWithToken({token: token}));
    } else if (parsedParams.get('token')) {
      // This is a signin, so let's look for the Stytch token to complete the flow
      const token = parsedParams.get('token');
      parsedParams.delete('signupType');
      parsedParams.delete('token');
      history.replace({
        search: parsedParams.toString(),
      });
      dispatch(completeAuthWithToken({token: token}));
    } else if (signupType === 'paymentSuccess') {
      parsedParams.delete('signupType');
      parsedParams.delete('plan');
      history.replace({
        search: parsedParams.toString(),
      });
      dispatch(getCurrentUser(true));
    } else if (refreshUser) {
      // Force a refresh of the user session b/c user is coming back from payment portal
      dispatch(getCurrentUser(true));
    } else {
      // Neither a signup or signin, so just get the current user
      dispatch(getCurrentUser());
    }
  }, []);

  return (
    <OnboardingModal
      open={open}
      setOpen={setOpen}
      signupType={signupType}
      plan={plan}/>
  );
}