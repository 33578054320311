import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import companiesAPI from './api';
import posthog from 'posthog-js'
import { getMetricsForCompany } from "../../services/calculations";

export const getCompanyById = createAsyncThunk(
  'companies/getCompanyById',
  async (id) => {
    const response = await companiesAPI.getCompanyById(id);
    return response.data;
  }
);

export const updateCompany = createAsyncThunk(
  'companies/updateCompany',
  async (data, thunkAPI) => {
    thunkAPI.dispatch(updateCompanyInLocalStore(data));
    const response = await companiesAPI.updateCompany(data);
    posthog.capture('update company');
    return response.data;
  }
);

export const deleteCompany = createAsyncThunk(
  'companies/deleteCompany',
  async (companyId, thunkAPI) => {
    thunkAPI.dispatch(deleteCompanyInLocalStore(companyId));
    const response = await companiesAPI.deleteCompany(companyId);
    posthog.capture('delete company');
    return response.data;
  }
);

export const searchCompanies = createAsyncThunk(
  'companies/searchCompanies',
  async (query) => {
    const response = await companiesAPI.searchCompanies(query);
    return response.data;
  }
);

export const getRecentCompanies = createAsyncThunk(
  'companies/getRecentCompanies',
  async () => {
    const response = await companiesAPI.getRecentCompanies();
    return response.data;
  }
);

export const createCompanyWithUpload = createAsyncThunk(
  'companies/createCompanyWithUpload',
  async (_, thunkAPI) => {
    let company = thunkAPI.getState().companiesSlice.companies['upload'];
    let data = {
      name: company.name,
      data: company.lastUpload.data,
      metrics: getMetricsForCompany(company),
      settings: company.settings
    };
    const response = await companiesAPI.createCompanyWithUpload(data);
    posthog.capture('create company');
    return response.data;
  }
);

export const getCompDataForCompany = createAsyncThunk(
  'companies/getCompDataForCompany',
  async (id) => {
    const response = await companiesAPI.getCompDataForCompany(id);
    return response.data;
  }
);

export const companiesSlice = createSlice({
  name: 'companiesSlice',
  initialState: {
    companies: {},
    lastUploadedId: null,
    showUploadSuccess: false,
    recentCompanies: null,
    createCompanyWithUploadPending: false,
    getCompanyByIdPending: true
  },
  reducers: { 
    updateCompanyInLocalStore: (state, action) => {
      delete action.payload.lastUpload;
      state.companies = {
        ...state.companies,
        [action.payload._id]: {...state.companies[action.payload._id], ...action.payload}
      };
    },
    uploadCompanyToLocalStore: (state, action) => {
      state.companies = {
        ...state.companies,
        [action.payload._id]: action.payload
      }
      state.getCompanyByIdPending = false;
    },
    deleteCompanyInLocalStore: (state, action) => {
      delete state.companies[action.payload];
    },
    clearLastUploadedId: (state) => {
      state.lastUploadedId = null;
    },
    setShowUploadSuccess: (state, action) => {
      state.showUploadSuccess = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getCompanyById.fulfilled, (state, action) => {
      state.companies[action.payload._id] = action.payload;
      state.getCompanyByIdPending = false;
    });
    builder.addCase(getCompanyById.pending, (state, action) => {
      if (!(action.meta.arg in state)) {
        state.getCompanyByIdPending = true;
      }
    });
    builder.addCase(getRecentCompanies.fulfilled, (state, action) => {
      state.recentCompanies = action.payload;
    });
    builder.addCase(createCompanyWithUpload.pending, (state, action) => {
      state.createCompanyWithUploadPending = true;
    });
    builder.addCase(createCompanyWithUpload.fulfilled, (state, action) => {
      state.companies = {
        ...state.companies,
        [action.payload._id]: {...state.companies['upload'], ...action.payload}
      };
      state.lastUploadedId = action.payload._id;
      state.createCompanyWithUploadPending = false;
      state.showUploadSuccess = true;
    });
  }
});

export const { updateCompanyInLocalStore,
               uploadCompanyToLocalStore,
               deleteCompanyInLocalStore,
               clearLastUploadedId,
               setShowUploadSuccess } = companiesSlice.actions;

export default companiesSlice.reducer;