import * as d3 from 'd3';

export const LineCompsCellSVG = (companies, {
  width = 400,
  height = 174,
  maxBoundY = null,
  minBoundY = null
} = {}) => {
  const margin = {top: 30, right: 50, bottom: 30, left: 50};

  let allPoints = [].concat.apply([], companies.map((c,i) => c.points.map(p => {return {x: p.x, y:p.y, _id:c._id, name: c.name}})));
  const xVals = allPoints.map(p => p.x);
  const yVals = allPoints.map(p => p.y);

  // X and Y scale
  const xScale = d3.scaleLinear()
    .domain(d3.extent(xVals))
    .range([margin.left, width - margin.right]);

  let yExtent = d3.extent(yVals);
  let maxY;
  let minY;
  if (maxBoundY) {
    maxY = Math.min(yExtent[1], parseInt(maxBoundY));
  } else {
    maxY = yExtent[1];
  }
  
  if (minBoundY) {
    minY = Math.max(yExtent[0], parseInt(minBoundY));
  } else {
    minY = yExtent[0];
  }

  const yScale = d3.scaleLinear()
    .domain([maxY, minY])
    .range([margin.top, height - margin.bottom])

  const line = d3.line()
    .x(d => xScale(d.x))
    .y(d => yScale(d.y));

  const svg = d3.create('svg')
    .attr('width', width)
    .attr('height', height)
    .attr('viewBox', [0, 0, width, height])
    .attr('style', 'max-width: 100%; height: auto; height: intrinsic;')
    .style('-webkit-tap-highlight-color', 'transparent')
    .on('pointerenter', pointerentered)
    .on('pointermove', pointermoved)
    .on('pointerleave', pointerleft)
    .on('touchstart', event => event.preventDefault());

  const path = svg.append('g')
    .attr('fill', 'none')
    .attr('stroke-linecap', 'butt')
    .attr('stroke-linejoin', 'miter')
    .attr('stroke-opacity', 1)
  .selectAll('path')
  .data(companies)
  .join('path')
    .style('mix-blend-mode', 'multiple')
    .attr('stroke', (_) => 'steelblue')
    .attr('stroke-width', (_,i) => i === 0 ? 2 : 0.5)
    .attr('d', (company) => line(company.points));
  
  const yAxis = d3.axisLeft(yScale).tickFormat(d3.format('.0%'));
  const xAxis = d3.axisBottom(xScale)
    .tickFormat((v) => `M${v}`)
    .tickValues(Array.from(new Set(xVals)).filter(xVal => xVal % 6 === 0));

  svg.append('g')
    .attr('transform', `translate(${margin.left},0)`)
    .call(yAxis);

  svg.append('g')
    .attr('transform', `translate(0,${height-margin.bottom})`)
    .call(xAxis);

  const dot = svg.append("g")
      .attr("display", "none");

  dot.append("circle")
      .attr("r", 2.5);

  dot.append("text")
      .attr("font-family", "sans-serif")
      .attr("font-size", 10)
      .attr("text-anchor", "middle")
      .attr("y", -8);

  function pointermoved(event) {
    const [xm, ym] = d3.pointer(event);
    const closestPoint = d3.least(allPoints, p => Math.hypot(xScale(p.x) - xm, yScale(p.y) - ym));
    path.style("stroke", (segment) => closestPoint._id === segment._id ? null : "#ddd").filter(segment => closestPoint._id === segment._id).raise();
    dot.attr("transform", `translate(${xScale(closestPoint.x)},${yScale(closestPoint.y)})`);
    dot.select("text").text(`${closestPoint.name}, ${d3.format('.0%')(closestPoint.y)} @ ${closestPoint.x}mo`);
    svg.property("value", closestPoint).dispatch("input", {bubbles: true});
  }

  function pointerentered() {
    path.style("mix-blend-mode", null).style("stroke", "#ddd");
    dot.attr("display", null);
  }

  function pointerleft() {
    path.style("mix-blend-mode", "multiply").style("stroke", null);
    dot.attr("display", "none");
    svg.node().value = null;
    svg.dispatch("input", {bubbles: true});
  }

  const element = svg.node();
  element.value = null;
  return element;
}