import React, { useEffect, useState } from 'react';
import * as d3 from 'd3';
import { interpolateTurbo } from 'd3-scale-chromatic';
import { generateBridgeData, segmentInputData } from '../../services/calculations';
import GroupSelectionWidget from '../../components/GroupSelectionWidget';
import { MultiLineChartCell } from '../../components/MultiLineChartCell';
import posthog from 'posthog-js'

function Segmentation(props) {
  const [legendSpec, setLegendSpec] = useState({});
  const [segmentedBridgeData, setSegmentedBridgeData] = useState([]);

  useEffect(() => {
    if (props.live && props.company) {
      posthog.capture('view segmentation', {companyId: props.company._id});
    }
  }, [props.company]);

  const handleKeySelection = (key, defaultSelection) => {
    let segments = segmentInputData(props.company.lastUpload.data, key);
    let segmentedBridges = [];
    for (let s=0; s<segments.length; s++) {
      let bridgeData = generateBridgeData(segments[s], 'Customer', props.company?.settings?.fiscalYearEnd, props.company?.settings?.convertToQuarters);
      segmentedBridges.push({
        name: segments[s].name,
        bridgeData: bridgeData
      });
    }
    generateLegendSpec(segmentedBridges);
    setSegmentedBridgeData(segmentedBridges);
    if (props.live && !defaultSelection) {
      posthog.capture('select segmentation key', {companyId: props.company._id});
    }
  }

  const generateLegendSpec = (bridges) => {
    let sortedBridges = [...bridges];
    sortedBridges.sort((a, b) => b.bridgeData[b.bridgeData.length-1].eop - a.bridgeData[a.bridgeData.length-1].eop);
    let newLegendSpec = {};
    for (let s=0; s<sortedBridges.length; s++) {
      newLegendSpec[sortedBridges[s].name] = {
        rank: s,
        color: interpolateTurbo(s/5) // 5 bc 6 is set as max segments (5 named, 1 other)
      }
    }
    setLegendSpec(newLegendSpec);
  }

  const generateConsolidatedTriples = (key) => {
    let triples = [];
    for (let s=0; s<segmentedBridgeData.length; s++) {
      for (let i=0; i<segmentedBridgeData[s].bridgeData.length; i++) {
        triples.push({
          segment: segmentedBridgeData[s].name,
          date: new Date(segmentedBridgeData[s].bridgeData[i].date),
          value: segmentedBridgeData[s].bridgeData[i][key],
        })
      }
    }
    return triples;
  }

  return (
    <div className='w-full'>
      <div className='w-full flex mb-10 mt-5'>
        <div className='text-2xl'>By Tag</div>
        <GroupSelectionWidget
          data={props.convertedData}
          handleKeySelection={handleKeySelection}/>
      </div>
      <div className='w-full flex flex-wrap'>
        { segmentedBridgeData.length > 0 && <>
        <MultiLineChartCell
          title='ARR'
          valueFormat={(v) => `$${d3.format('.1f')(v/1000000)}m`}
          periodLength={props.convertedData.periodLength}
          headerRef={props.headerRef}
          triples={generateConsolidatedTriples('eop')}
          legendSpec={legendSpec}
        />
        <MultiLineChartCell
          title='Gross Retention'
          valueFormat={d3.format('0.0%')}
          maxBoundY={1}
          minBoundY={0}
          periodLength={props.convertedData.periodLength}
          headerRef={props.headerRef}
          triples={generateConsolidatedTriples('LTMgross')}
          legendSpec={legendSpec}
        />
        <MultiLineChartCell
          title='Net Retention'
          valueFormat={d3.format('0.0%')}
          maxBoundY={2}
          minBoundY={0}
          periodLength={props.convertedData.periodLength}
          headerRef={props.headerRef}
          triples={generateConsolidatedTriples('LTMnet')}
          legendSpec={legendSpec}
        />
        <MultiLineChartCell
          title='ACV'
          valueFormat={(v) => `$${d3.format('.1f')(v/1000)}`}
          periodLength={props.convertedData.periodLength}
          headerRef={props.headerRef}
          triples={generateConsolidatedTriples('dollarsPerAll')}
          legendSpec={legendSpec}
        />
        <MultiLineChartCell
          title='Logos'
          valueFormat={(v) => parseInt(v)}
          periodLength={props.convertedData.periodLength}
          headerRef={props.headerRef}
          triples={generateConsolidatedTriples('eopLogos')}
          legendSpec={legendSpec}
        />
        </>}
      </div>
    </div>
  );
}

export default Segmentation;
