import { CheckIcon } from '@heroicons/react/outline'
import { useEffect, useState } from 'react'
import FeatureShowcase from '../../components/FeatureShowcase'
import posthog from 'posthog-js';

const features = [
  {
    name: 'Instant view of key metrics',
    description: 'Automatically generated interactive graphs of ARR, ACV, logos, and gross/net retention',
    key: 'metrics'
  },
  {
    name: 'Firm-wide benchmarking',
    description: 'Instantly compare key metrics across every company you\'ve evaluated',
    key: 'benchmarking'
  },
  {
    name: 'Download to Excel',
    description: 'Generate a flowing formula file; no more spending hours to build it',
    key: 'download'
  },
  {
    name: 'Interactive bridge',
    description: 'Click into unusual months/quarters to see what happened with specific customers',
    key: 'bridge'
  },
  {
    name: 'Cohort charts',
    description: 'See net & logo retention charts, automatically heat-colored to see trends',
    key: 'cohorts'
  },
  {
    name: 'Segmentation',
    description: 'Compare customer segments with one click; see if a geography or product type is performing better',
    key: 'segmentation'
  },
  {
    name: 'Customer concentration & rollforward',
    description: 'Click periods to see top customers and how they change over time',
    key: 'rollforward'
  },
  {
    name: 'Top events',
    description: 'Immediately see the biggest major new, upsell, downsell, and churn events',
    key: 'events'
  },
]

export default function Features() {
  const [feature, setFeature] = useState(null);
  useEffect(() => {
    posthog.capture('view features');
  }, []);

  return (
    <>
    <div className="max-w-7xl mx-auto bg-white pt-16 sm:pt-24 sm:px-6 lg:px-8">
        <div className="relative z-10 text-center text-gray-800 max-w-2xl px-4 sm:px-3 mx-auto pt-16 sm:pt-18 xl:pt-24 sm:pb-16 lg:pb-10 xl:pb-14">
        <h1 className="text-4xl sm:text-5.5xl font-extrabold tracking-tight mb-24 sm:mb-18">The <span className='text-indigo-500'>v</span>Final Platform</h1>
      <p className="max-w-xl mx-auto text-indigo-400 text-3xl hidden sm:block">Click a feature to explore</p>
        </div>
    </div>
    <dl className="space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 lg:grid-cols-4 lg:gap-x-8 lg:mx-12">
    {features.map((feature) => (
      <div key={feature.name} className="relative border border-white hover:border-indigo-600 rounded-md p-2 -m-2 cursor-pointer" onClick={() => setFeature(feature.key)}>
        <dt>
          <CheckIcon className="absolute h-6 w-6 text-green-500" aria-hidden="true" />
          <p className="ml-9 text-lg leading-6 font-medium text-gray-900">{feature.name}</p>
        </dt>
        <dd className="mt-2 ml-9 text-base text-gray-500">{feature.description}</dd>
      </div>
    ))}
    </dl>
    <FeatureShowcase featureKey={feature}/>
  </>
  )
}
