import React, { useState, useRef, useEffect } from 'react';
import * as d3 from 'd3';
import { getTopEventsInIndexRange } from '../../services/calculations';

export function EventsExplorer(props) {
  const [dataColumns, setDataColumns] = useState([]);
  const [labelCells, setLabelCells] = useState([]);
  const ref = useRef(null);

  const sections = ['new', 'upsell', 'downsell', 'churn', 'reactivation'];
  
  const showTopEvents = async () => {
    let startIndex = props.rangeMonths / props.inputData.periodLength;
    let topEvents = getTopEventsInIndexRange(props.inputData, props.inputData.dates.length-startIndex, props.inputData.dates.length);

    // Data cells
    let newCols = [];
    let newLabelCells = [];
    for (let c=0; c<props.inputData.dates.length; c++) {
      let newCells = [];

      for (let s=0; s<sections.length; s++) {
        let sectionCustomers = topEvents[sections[s]];

        if (s === 0) {
          newCells.push(<div
            key={`date-${c}`}
            className={`pt-1 h-5 text-center text-xs w-20 mb-2 font-bold`}
            >{props.headerRef[new Date(props.inputData.dates[c])]}</div>);
        }

        for (let sc=0; sc<Math.min(sectionCustomers.length, 5); sc++) {
          if (sc === 0) {
            newCells.push(<div
              key={`${sections[s]}-header`}
              className='pt-1 h-5 text-right text-xs w-20 mt-2'
              ></div>);
            
            if (c === 0) {
              newLabelCells.push(<div
                key={`${sections[s]}-header`}
                className={`pt-1 h-5 text-left text-xs w-48 font-bold mt-2`}
                >{sections[s].charAt(0).toUpperCase() + sections[s].slice(1)}</div>)
            }
          }
          let highlightClass = '';
          if (sectionCustomers[sc][2].includes(c) || sectionCustomers[sc][2].includes(c+1)) {
            if (sections[s] === 'downsell' || sections[s] === 'churn') {
              highlightClass = ' bg-red-100';
            } else {
              highlightClass = ' bg-green-100';
            }
          }
          newCells.push(<div
            key={`${c}-${sections[s]}-${sc}`}
            className={`pr-1 pt-1 h-5 text-right text-xs w-20${highlightClass}`}
            >{getDisplayValue('integer', sectionCustomers[sc][0].values[c])}</div>);
          
          if (c === 0) {
            newLabelCells.push(<div
              key={`${s}-${sectionCustomers[sc][0].keys.Customer}`}
              className={`pt-1 pl-2 pr-2 h-5 text-left text-xs w-48 truncate`}
              >{sectionCustomers[sc][0].keys.Customer}</div>)
          }
        }
      }
      newCols.push(newCells);
    }

    setLabelCells(newLabelCells);
    setDataColumns(newCols);
  }

  useEffect(() => {
    if (props.inputData) {
      showTopEvents();
    }
  }, [props.inputData]);

  useEffect(() => {
    ref.current.scrollLeft = ref.current.scrollWidth;
    ref.current.classList.remove('hidden');
  }, [dataColumns]);


  function getDisplayValue(dataType, value) {
    if (dataType === 'integer') {
      if (value >= 0) {
        return `${d3.format(",")(parseInt(value))} `;
      }
      return `(${d3.format(",")(Math.abs(parseInt(value)))})`;
      
    } else if (dataType === 'percentage') {
      return d3.format(".1%")(value);
    } else if (dataType === 'blank' || isNaN(value)) {
      return '';
    }
  }

  return (
    <div className='w-full tabular-nums mb-20'> 
      <div className='flex overflow-x-scroll w-full hidden' ref={ref}>
        <div className='sticky left-0 w-48 bg-white p-1'>
          <div key='blank-holder' className='h-5 w-48 mb-2'></div>
          { labelCells }
        </div>
        { dataColumns.map((columnCells, c) => {
          return <div
            key={`c-${c}`}
            className={'py-1'}
            >
            {columnCells}
          </div>
        }) }
      </div>
    </div>
  );
};