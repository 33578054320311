import React, { useEffect } from 'react';
import { useRef } from 'react';

import { generateWaterfallCells, getPeriodHeaderRefFromInputData } from '../../services/calculations';
import { CohortGridSVG } from './CohortGridSVG';

export function CohortGrid(props) {
  const ref = useRef(null);
  useEffect(() => {
    if (props.data) {
      let cellData = generateWaterfallCells(props.data, props.retType, props.settings);
      let dates = Array.from(new Set(cellData.map(cd => cd.cohortDate)));
      let headerRef = getPeriodHeaderRefFromInputData({dates: dates, periodLength: props.data.periodLength}, props.settings);
      let periodPrefix = props.data.periodLength === 3 || (props.data.periodLength === 1 && props.settings?.convertToQuarters) ? 'Q' : 'M';
      let el = CohortGridSVG(cellData, {headerRef: headerRef, periodPrefix: periodPrefix, retType: props.retType});
      if (ref.current) {
        ref.current.innerHTML = '';
        ref.current.appendChild(el);
      }
    }
  }, [props.data, props.settings]);
  
  return (
    <div className={props.containerClassName}>
      <div className='w-full px-2'><h2>{props.retType.charAt(0).toUpperCase() + props.retType.slice(1)} Retention</h2></div>
      <div className='pl-2' ref={ref}></div>
      </div>
  );
}