import axios from 'axios';

const usersAPI = {
  completeAuthWithToken: async (data) => {
    return await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/users/authenticate`,
      data: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json'
      },
      withCredentials: true
    })
  },
  createUserWithCompany: async (data) => {
    return await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/users`,
      data: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json'
      },
      withCredentials: true
    })
  },
  createUser: async (data) => {
    return await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/users`,
      data: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json'
      },
      withCredentials: true
    })
  },
  sendLoginRequest: async (data) => {
    return await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/users/login`,
      data: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json'
      },
      withCredentials: true
    })
  },
  getCurrentUser: async (params) => {
    return await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/users/current`,
      params: params,
      withCredentials: true
    })
  },
  sendInvite: async (data) => {
    // data: email
    return await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/users/invite`,
      data: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json'
      },
      withCredentials: true
    })
  }
}

export default usersAPI;