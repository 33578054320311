import { useEffect, useState } from "react"

export default function GroupSelectionWidget(props) {
  const [keys, setKeys] = useState([]);

  useEffect(() => {
    if (props.data && props.data.rows.length > 0) {
      let filteredKeys = Object.keys(props.data.rows[0].keys).filter(k => k !== 'Customer');
      props.handleKeySelection(filteredKeys[0], true);
      setKeys(filteredKeys);
    }
  }, [props.data]);

  return (
    <div className='ml-12'>
      { keys.length > 0 &&
      <select
        name='key'
        className='mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md'
        default={keys[0]}
        onChange={(e) => props.handleKeySelection(e.target.value, false)}
      >
        {keys.map(key => {
          return <option key={key}>{key}</option>
        })}
      </select>
      }
    </div>
  )
}