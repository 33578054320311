import React, { useState, useRef, useEffect } from 'react';
import * as d3 from 'd3';

export function CustomersExplorer(props) {
  const [dataColumns, setDataColumns] = useState([]);
  const [labelCells, setLabelCells] = useState([]);
  const [detailColumns, setDetailColumns] = useState([]);
  const [clicked, setClicked] = useState(false);
  const ref = useRef(null);
  
  const showTopCustomersForDateIndex = async (di, numCustomers) => {
    let sortedRows = [...props.inputData.rows];
    sortedRows.sort((r1, r2) => r2.values[di] - r1.values[di]);
    sortedRows = sortedRows.filter((r) => r.values[di] > 0 ? true : false);
    let concentrationValues = getConcentrationValues(sortedRows, di);
    concentrationValues = concentrationValues.slice(0, numCustomers);
    let deltaValues = getDeltaValues(sortedRows.slice(0, numCustomers), di);

    let newCols = [];
    let newLabelCells = [];
    let concentrationCells = [];
    let deltaCells = [];

    let concentrationDate = '';
    if (di !== props.inputData.dates.length-1) {
      concentrationDate = props.headerRef[new Date(props.inputData.dates[di])];

      deltaCells.push(<div
        key={`concentration-header-1`}
        className={`h-5 text-center text-xs w-20 font-bold`}
        ></div>);

      deltaCells.push(<div
        key={`concentration-header-2`}
        className={`h-5 text-center text-xs w-20 mb-2 font-bold`}
        >% Change</div>);
    }
    concentrationCells.push(<div
      key={`concentration-header-1`}
      className={`h-5 text-center text-xs w-20 font-bold`}
      >{concentrationDate}</div>);

    concentrationCells.push(<div
      key={`concentration-header-2`}
      className={`h-5 text-center text-xs w-20 mb-2 font-bold`}
      >% of Total</div>);
    
    newLabelCells.push(<div
      key={'label-cells-spacer'}
      className={`h-5 text-left text-xs w-48 truncate`}
      ></div>)

    for (let c=0; c<props.inputData.dates.length; c++) {
      let cells = [];
      for (let r=0; r<Math.min(numCustomers, sortedRows.length); r++) {
        let displayValue = getDisplayValue('integer', sortedRows[r].values[c]);

        if (r === 0) {
          cells.push(<div
            key={`spacer-${c}`}
            className={`h-5 text-center text-xs w-20 font-bold`}
            ></div>);
          cells.push(<div
            key={`date-${c}`}
            className={`h-5 text-center text-xs w-20 mb-2 font-bold`}
            >{props.headerRef[new Date(props.inputData.dates[c])]}</div>);
        }

        cells.push(<div
          key={`${r}-${c}`}
          className='h-5 text-right text-xs w-20'
          >{displayValue}</div>);
        
        if (c === 0) {
          newLabelCells.push(<div
            key={sortedRows[r].keys.Customer}
            className={`h-5 text-left text-xs w-48 truncate`}
            >{`${r+1}. ${sortedRows[r].keys.Customer}`}</div>)
          
          concentrationCells.push(<div
            key={`concentration-${r}`}
            className='h-5 text-right text-xs w-20'
            >{getDisplayValue('percentage', concentrationValues[r])}</div>);
          
          let deltaValue = '';
          let deltaColor = '';
          if (di !== props.inputData.dates.length-1) {
            deltaValue = getDisplayValue('percentage', deltaValues[r], true);
            if (deltaValues[r] < 0) {
              deltaColor = ' bg-red-100';
            } else if (deltaValues[r] > 0) {
              deltaColor = ' bg-green-100';
            }
          }
          
          deltaCells.push(<div
            key={`deltas-${r}`}
            className={`h-5 text-right text-xs w-20${deltaColor}`}
            >{deltaValue}</div>);
        }
      }
      newCols.push(cells);
    }
    setLabelCells(newLabelCells);
    setDataColumns(newCols);
    setDetailColumns([concentrationCells, deltaCells]);
  }

  const getConcentrationValues = (rows, i) => {
    let total = rows.reduce((currentSum, currentRow) => currentSum + currentRow.values[i], 0);
    return rows.map(r => r.values[i] / total);
  }

  const getDeltaValues = (rows, i) => {
    return rows.map(r => r.values[props.inputData.dates.length-1] / r.values[i] - 1);
  }

  useEffect(() => {
    if (props.inputData) {
      showTopCustomersForDateIndex(props.inputData.dates.length-1, 25);
    }
  }, [props.inputData]);

  useEffect(() => {
    if (!clicked) {
      ref.current.scrollLeft = ref.current.scrollWidth;
    }
    ref.current.classList.remove('hidden');
  }, [dataColumns]);

  function getDisplayValue(dataType, value, explicitPlus=false) {
    if (dataType === 'integer') {
      if (value >= 0) {
        return `${explicitPlus ? '+' : ''}${d3.format(",")(parseInt(value))} `;
      }
      return `(${d3.format(",")(Math.abs(parseInt(value)))})`;
      
    } else if (dataType === 'percentage') {
      return `${explicitPlus && value > 0 ? '+' : ''}${d3.format(".1%")(value)}`;
    } else if (dataType === 'blank' || isNaN(value)) {
      return '';
    }
  }

  return (
    <div className='w-full tabular-nums mb-20'> 
      <div className='flex overflow-x-scroll w-full hidden' ref={ref}>
        <div className='sticky left-0 w-48 bg-white p-1'>
          <div key='blank-holder' className='h-5 w-48 mb-2'></div>
          { labelCells }
        </div>
        { dataColumns.map((columnCells, c) => {
          return <div
            key={`c-${c}`}
            className={'py-1 cursor-pointer hover:bg-gray-100'}
            onClick={() => {setClicked(true); showTopCustomersForDateIndex(c, 25);}}
            >
            {columnCells}
          </div>
        }) }
        <div className='sticky right-0 w-40 bg-white flex'>
        { detailColumns.map((columnCells, c) => {
          return <div
            key={`dc-${c}`}
            className={'py-1'}
            >
            {columnCells}
          </div>
        }) }
        </div>
      </div>
    </div>
  );
};