import React, { useEffect, useState } from 'react';
import { useRef } from 'react';
import * as d3 from 'd3';
import { MultiLineChartCellSVG } from './MultiLineChartCellSVG';

export function MultiLineChartCell(props) {
  const ref = useRef(null);
  const [hoverTriples, setHoverTriples] = useState(null);
  
  let labelTriples = [];
  if (props.triples && props.triples.length > 0 && props.legendSpec) {

    if (!hoverTriples) {
      let uniqueX = Array.from(new Set(props.triples.map(t => t.date))).sort((a,b) => a.getTime()-b.getTime());
      let defaultTime = uniqueX[uniqueX.length-1].getTime();
      labelTriples = props.triples.filter(t => t.date.getTime() === defaultTime);
    } else {
      labelTriples = hoverTriples;
    }

    if (props.legendSpec) {
      try {
        labelTriples.sort((a,b) => props.legendSpec[a.segment].rank - props.legendSpec[b.segment].rank);
      } catch (e) {}
    }
    
    useEffect(() => {
      let el = MultiLineChartCellSVG(props.triples, {
        width: 400,
        height: 174,
        maxBoundY: props.maxBoundY,
        minBoundY: props.minBoundY,
        headerRef: props.headerRef,
        setHoverTriples: (d) => setHoverTriples(d),
        legendSpec: props.legendSpec
      });
      ref.current.innerHTML = '';
      ref.current.appendChild(el);
    }, [props.triples])
  }

  return (
    <div className='box-width-half'>
      <div><a href='/blog/metrics' target='_blank'>{props.title}</a></div>
      { props.triples && <>
      <div className='flex'>
        <div style={{height: '174px'}} ref={ref}></div>
        <div>
        { labelTriples && labelTriples.length > 0 && props.legendSpec && <>
            <div className={`text-sm align-text-bottom mr-2 ${hoverTriples ? 'text-gray-400' : 'text-white'}`}>{props.headerRef[labelTriples[0].date]}</div>
            {
              labelTriples.map(t => {return <div key={t.segment} className='flex items-center'>
                <div className='rounded-full w-2 h-2' style={{backgroundColor: props.legendSpec[t.segment] ? props.legendSpec[t.segment].color : ''}}></div>
                <div className='text-sm align-text-bottom mr-2 ml-2'>{t.segment}</div>
                <div className='text-sm text-gray-400 align-text-bottom mr-2'>{!isNaN(t.value) ? props.valueFormat(t.value) : 'NA'}</div>
              </div>})
            }
          </>
        }
        </div>
      </div>
      </> }
    </div>
  );
}