import Papa from 'papaparse';

// PUBLIC

export const extractDataFromFileInputRows = (fileInputRows) => {
  let headers = fileInputRows[0];
  let cleanedHeaders = [{type: 'key', name: 'Customer'}];
  let firstDateIndex;
  for (let h=1; h<headers.length; h++) {
    let date = getDate(headers[h]);
    if (date === null) {
      if (firstDateIndex) break; // Found another non-date after seeing dates, so call this the end
      if (headers[h].length !== 0) {
        cleanedHeaders.push({type: 'key', name: headers[h] !== 'Customer' ? headers[h] : '_Customer'});
      }
    } else if (date !== null) {
      cleanedHeaders.push({type: 'date', name: date.toDateString()}); // Map date into string so Redux can serialize it
      if (!firstDateIndex) firstDateIndex = h;
    }
  }
  
  let rows = [];
  for (let r=1; r<fileInputRows.length; r++) {
    let rowOutput = {
      keys: {},
      values: []
    };
    let objValues = fileInputRows[r];
    if (objValues.length === 0) continue;
    for (let h=0; h<cleanedHeaders.length; h++) {
      if (cleanedHeaders[h].name === 'customer' && objValues[h].length === 0) {
        break;
      }
      if (cleanedHeaders[h].type === 'key') {
        rowOutput.keys[cleanedHeaders[h].name] = objValues[h];
      } else break;
    }
    if (objValues.length <= firstDateIndex) {
      continue;
    }
    rowOutput.values = objValues.slice(firstDateIndex, cleanedHeaders.length).map(v => {
      let cleanV = v.replace(/[^0-9.()-]/g,'');
      let matches = cleanV.match(/^\(([0-9.]+)\)$/);
      if (matches != null) {
        cleanV = matches[1];
        return cleanV === '' ? 0 : parseFloat(cleanV) * -1;
      }
      matches = cleanV.match(/^-([0-9.]+)$/);
      if (matches != null) {
        cleanV = matches[1];
        return cleanV === '' ? 0 : parseFloat(cleanV) * -1;
      }
      cleanV = cleanV.replace(/[^0-9.]/g,'');
      return cleanV === '' ? 0 : parseFloat(cleanV);
    });
    rows.push(rowOutput);
  }

  let output = {
    rows: rows,
    dates: cleanedHeaders.splice(firstDateIndex).map(h => h.name)
  }
  output.periodLength = getPeriodLength(new Date(output.dates[0]), new Date(output.dates[1]));
  return output;
}

export const validateAndParse = (files) => {
  return new Promise(resolve => {
    if (files.length === 0) {
      return resolve({success: false, message: 'No files found. Try again.'});
    } else if (files.length > 1) {
      return resolve({success: false, message: 'Multiple files found. Please just upload one file at a time.'});
    }
    let file = files[0];
    if (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      return resolve({success: false, message: 'You uploaded an XLSX file. Please convert this file to CSV (export to CSV from Excel), and then reupload it.'});
    }
    
    Papa.parse(file, {
      complete: (results) => {
        if (Object.prototype.toString.call(results.data) !== '[object Array]') {
          return resolve({success: false, message: 'No data found. Please make sure you are uploading a CSV from Excel.'});
        } else if (results.data.length <= 1) {
          return resolve({success: false, message: 'No data found. Please make sure your CSV file is not empty.'});
        }

        let objKeys = results.data[0];
        let foundDate = false;
        for (let d=0; d<objKeys.length; d++) {
          if (objKeys[d].match(/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/)) {
            foundDate = true;
            break;
          }
        }
        if (!foundDate) {
          return resolve({success: false, message: 'No "yyyy-mm-dd" formatted dates found. Please make sure you\'re using the "yyyy-mm-dd" format (e.g. 2022-01-20) and they\'re in row 1.'});
        }

        try {
          let data = extractDataFromFileInputRows(results.data);
          for (let r=0; r<data.rows.length; r++) {
            for (let v=0; v<data.rows[r].values.length; v++) {
              if (data.rows[r].values[v] < 0) {
                return resolve({success: false, message: `Your data has negative numbers, which does not make sense for ARR. See row ${r+2} as an example.`});
              }
            }
          }
          let periodDays = data.periodLength === 1 ? 30 : 90;
          const day = 24 * 60 * 60 * 1000;
          for (let d=2; d<data.dates.length; d++) {
            const diffDays = Math.round((new Date(data.dates[d]) - new Date(data.dates[d-1])) / day);
            if (Math.abs(diffDays / periodDays - 1) > 0.1) {
              let periodType = data.periodLength === 1 ? 'monthly' : 'quarterly';
              return resolve({success: false, message: `You uploaded what looks like ${periodType} data, but not all dates are ${periodDays === 30 ? '1 month' : '3 months'} apart from each other. ${diffDays} days between ${data.dates[d]} and ${data.dates[d-1]}.`});
            }
          }
          return resolve({success: true, data: data});
        } catch (e) {
          return resolve({success: false, message: 'Invalid file format. Please check to make sure you are uploading a CSV and try again.'});
        }
      }
    });
  });
}



// PRIVATE

const getDate = (headerString) => {
  // Returns a date object if month, null if not
  let date = new Date(headerString);
  date = new Date(date.getTime() + (date.getTimezoneOffset() * 60000));
  if (date instanceof Date && !isNaN(date)) {
    return date;
  }
  return null;
}

const getPeriodLength = (d1, d2) => {
  const day = 24 * 60 * 60 * 1000;
  const diffDays = Math.round((d2 - d1) / day);
  if (diffDays > 25 && diffDays < 35) {
    return 1; // months
  } else if (diffDays > 80 && diffDays < 100) {
    return 3; // quarters
  }
}