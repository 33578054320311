import axios from 'axios';

const paymentsAPI = {
  createStripeCheckoutSession: async (data) => {
    return await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/payments/checkout-session`,
      data: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json'
      },
      withCredentials: true
    })
  },
  createStripePortalSession: async () => {
    return await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/payments/portal-session`,
      headers: {
        'Content-Type': 'application/json'
      },
      withCredentials: true
    })
  }
}

export default paymentsAPI;