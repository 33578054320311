import { CheckIcon } from '@heroicons/react/outline'
import { useEffect, useState } from 'react'
import posthog from 'posthog-js';

export default function Home() {
  useEffect(() => {
    posthog.capture('view home');
  }, []);

  return (
    <>
    <div className="mt-16 sm:mt-24 lg:mt-32 lg:grid lg:grid-cols-12 lg:gap-8">
      <div className="text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left">
        <h1>
          <span className="mt-1 block text-4xl tracking-tight font-extrabold sm:text-5xl xl:text-6xl">
            <span className="block text-gray-900">Generate your <span className="block text-indigo-600">ARR analytics</span> in 15 seconds</span>
            
          </span>
        </h1>
        <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">Upload a customer cube and see everything you need</p>
        <div className="mt-8 sm:max-w-lg sm:mx-auto text-center lg:text-left lg:mx-0">
        <div className="rounded-md shadow xl:w-48">
          <a
            href="/upload"
            className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-10"
          >
            Try it now
          </a>
        </div>
          <p className="mt-3 text-sm text-gray-500">No signup required, and we don't store you data unless you ask us to.</p>
        </div>
      </div>
      <div className="shadow-lg mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center overflow-visible hidden">
        <div className="relative mx-auto rounded-lg" style={{width: '2000px'}}>
            <img
              className='object-cover object-left-top sm:translate-x-16 lg:translate-y-20'
              src={`${process.env.PUBLIC_URL}/images/home-main.png`}
              alt=""
            />
        </div>
      </div>
    </div>
    <div className="bg-gray-50 pt-12 sm:pt-16 mt-20">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="max-w-4xl mx-auto text-center">
          <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">Trusted by the largest software investors</h2>
          <p className="mt-3 text-xl text-gray-500 sm:mt-4">Benchmarking hundreds of companies' metrics and saving 5+ hours per week for VPs and associates</p>
        </div>
      </div>
      <div className="mt-10 pb-12 bg-white sm:pb-16">
        <div className="relative">
          <div className="absolute inset-0 h-1/2 bg-gray-50"></div>
          <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="max-w-4xl mx-auto">
              <dl className="rounded-lg bg-white shadow-lg sm:grid sm:grid-cols-3">
                <div className="flex flex-col border-b border-gray-100 p-6 text-center sm:border-0 sm:border-r">
                  <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-500">Companies Analyzed</dt>
                  <dd className="order-1 text-5xl font-extrabold text-indigo-600">1,000+</dd>
                </div>
                <div className="flex flex-col border-t border-b border-gray-100 p-6 text-center sm:border-0 sm:border-l sm:border-r">
                  <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-500">ARR Benchmarked</dt>
                  <dd className="order-1 text-5xl font-extrabold text-indigo-600">$3bn+</dd>
                </div>
                <div className="flex flex-col border-t border-gray-100 p-6 text-center sm:border-0 sm:border-l">
                  <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-500">AUM in Customer Base</dt>
                  <dd className="order-1 text-5xl font-extrabold text-indigo-600">$40bn+</dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="relative bg-white pt-16 pb-32 overflow-hidden">
      <div className="relative">
        <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
          <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
            <div>
              <div>
                <span className="h-12 w-12 rounded-md flex items-center justify-center bg-indigo-600">
                  <svg className="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 10V3L4 14h7v7l9-11h-7z" />
                  </svg>
                </span>
              </div>
              <div className="mt-6">
                <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">Make educated decisions in minutes</h2>
                <p className="mt-4 text-lg text-gray-500">You're on a plane to meet the CEO.</p>
                <p className="mt-4 text-lg text-gray-500">VPs &amp; Associates are out sourcing the next deal.</p>
                <p className="mt-4 text-lg text-gray-500">Other firms are still iterating on analysis packs.</p>
                <div className="mt-6">
                  <a href="/signup" className="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700"> Get started </a>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-12 sm:mt-16 lg:mt-0">
            <div className="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
              <img className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none" src={`${process.env.PUBLIC_URL}/images/bridge.png`} alt="Inbox user interface"></img>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-24">
        <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
          <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
            <div>
              <div>
                <span className="h-12 w-12 rounded-md flex items-center justify-center bg-indigo-600">
                  <svg className="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 7v10c0 2.21 3.582 4 8 4s8-1.79 8-4V7M4 7c0 2.21 3.582 4 8 4s8-1.79 8-4M4 7c0-2.21 3.582-4 8-4s8 1.79 8 4m0 5c0 2.21-3.582 4-8 4s-8-1.79-8-4" />
                  </svg>
                </span>
              </div>
              <div className="mt-6">
                <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">Build a differentiated data asset for your firm</h2>
                <p className="mt-4 text-lg text-gray-500">What is best-in-class net retention for ACVs of $20-30k? Above 125% at 1 year.</p>
                <p className="mt-4 text-lg text-gray-500">How often do companies with $100k in ACV have less than 80% logo retention? Rarely - quick pass.</p>
                <div className="mt-6">
                  <a href="/signup" className="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700"> Get started </a>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
            <div className="pr-4 -ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full overflow-hidden">
              <img className="w-full rounded-xl shadow-xl lg:absolute lg:left-0 lg:h-2/3 lg:w-auto lg:max-w-none lg:mt-24" src={`${process.env.PUBLIC_URL}/images/list.png`} alt="Customer profile user interface"></img>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-24">
        <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
          <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
            <div>
              <div>
                <span className="h-12 w-12 rounded-md flex items-center justify-center bg-indigo-600">
                  <svg className="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                </span>
              </div>
              <div className="mt-6">
                <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">Reallocate your time to the needle-moving work</h2>
                <p className="mt-4 text-lg text-gray-500">Associates don't need to spend 5 hours building cohort analyses &amp; customer rollforwards.</p>
                <p className="mt-4 text-lg text-gray-500">VPs can spend more time on the key questions, not iterating on calculations.</p>
                <div className="mt-6">
                  <a href="/signup" className="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700"> Get started </a>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-12 sm:mt-16 lg:mt-0">
            <div className="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
              <img className="w-full rounded-xl shadow-xl ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none" src={`${process.env.PUBLIC_URL}/images/cohorts.png`} alt="Inbox user interface"></img>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
  )
}
