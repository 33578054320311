import { configureStore } from '@reduxjs/toolkit';
import mainReducer from '../mainSlice';
import usersReducer from '../data/users/slice';
import companiesReducer from '../data/companies/slice';
import paymentsReducer from '../data/payments/slice';

export default configureStore({
  reducer: {
    mainSlice: mainReducer,
    usersSlice: usersReducer,
    companiesSlice: companiesReducer,
    paymentsSlice: paymentsReducer
  },
});

// explanation here: https://react-redux.js.org/tutorials/quick-start