import { CheckIcon } from '@heroicons/react/outline'
import { useState } from 'react';
import { useDispatch } from 'react-redux'
import { createStripeCheckoutSession } from '../../data/payments/slice'

const tiers = [
  {
    name: 'Associate',
    href: '#',
    priceMonthly: 49,
    description: 'Turn 4 hours of analysis into 15 seconds',
    features: [
      'Download files',
      'Everything in free plan',
    ],
  },
  {
    name: 'Partner',
    href: '#',
    priceMonthly: 99,
    description: 'Insights that differentiate your decision-making',
    features: [
      'Benchmark across every company you\'ve seen',
      'Everything in associate plan'
    ],
  },
]

export default function Payment() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState({Associate: false, Partner: false});

  const handleSelection = async (plan) => {
    setLoading({[plan]: true});
    let session = await dispatch(createStripeCheckoutSession({plan: plan.toLowerCase()})).unwrap();
    setLoading({[plan]: false});
    window.location = session.url;
  }

  return (
    <div className="">
      <div className="pt-12 sm:pt-16 lg:pt-24">
        <div className="max-w-7xl mx-auto text-center px-4 sm:px-6 lg:px-8">
          <div className="max-w-3xl mx-auto space-y-2 lg:max-w-none">
            <p className="text-3xl font-extrabold text-gray-800 sm:text-4xl lg:text-5xl">
              Turn that 4 hours into 15 seconds
            </p>
            <p className="text-xl text-gray-500">
              10:1 ROI on your time, flawless work, and immediate insights
            </p>
          </div>
        </div>
      </div>
      <div className="mt-8 pb-12 sm:mt-12 sm:pb-16 lg:mt-16 lg:pb-24">
        <div className="relative">
          <div className="absolute inset-0 h-3/4" />
          <div className="relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="max-w-md mx-auto space-y-4 lg:max-w-5xl lg:grid lg:grid-cols-2 lg:gap-5 lg:space-y-0">
              {tiers.map((tier) => (
                <div key={tier.name} className="flex flex-col rounded-lg shadow-lg overflow-hidden">
                  <div className="px-6 py-8 bg-white sm:p-10 sm:pb-6">
                    <div>
                      <h3
                        className="inline-flex px-4 py-1 rounded-full text-sm font-semibold tracking-wide uppercase bg-indigo-100 text-indigo-600"
                        id="tier-standard"
                      >
                        {tier.name}
                      </h3>
                    </div>
                    <div className="mt-4 flex items-baseline text-6xl font-extrabold">
                      ${tier.priceMonthly}
                      <span className="ml-1 text-2xl font-medium text-gray-500">/mo</span>
                    </div>
                    <p className="mt-5 text-lg text-gray-500">{tier.description}</p>
                  </div>
                  <div className="flex-1 flex flex-col justify-between px-6 pt-6 pb-8 bg-gray-50 space-y-6 sm:p-10 sm:pt-6">
                    <ul role="list" className="space-y-4">
                      {tier.features.map((feature) => (
                        <li key={feature} className="flex items-start">
                          <div className="flex-shrink-0">
                            <CheckIcon className="h-6 w-6 text-green-500" aria-hidden="true" />
                          </div>
                          <p className="ml-3 text-base text-gray-700">{feature}</p>
                        </li>
                      ))}
                    </ul>
                    <div className="rounded-md shadow">
                      <span
                        onClick={() => handleSelection(tier.name)}
                        className="cursor-pointer flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gray-800 hover:bg-gray-900"
                        aria-describedby="tier-standard"
                      >
                        {loading[tier.name] ? 'Loading...' : 'Select'}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}