import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import usersAPI from './api';
import posthog from 'posthog-js'
import LogRocket from 'logrocket';
import * as Sentry from '@sentry/browser';

export const completeAuthWithToken = createAsyncThunk(
  'users/completeAuthWithToken',
  async (data) => {
    const response = await usersAPI.completeAuthWithToken(data);
    return response.data;
  }
);

export const createUserWithCompany = createAsyncThunk(
  'users/createUserWithCompany',
  async (data, { rejectWithValue }) => {
    try {
      const response = await usersAPI.createUserWithCompany(data);
      posthog.capture('create company with user');
      return response.data;
    } catch (err) {
      Sentry.captureException(err);
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const createUser = createAsyncThunk(
  'users/createUser',
  async (data, { rejectWithValue }) => {
    try {
      const response = await usersAPI.createUser(data);
      posthog.capture('create user', {email: data.email, conversionPath: data.conversion?.path, conversionPlan: data.conversion?.plan});
      return response.data;
    } catch (err) {
      Sentry.captureException(err);
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const sendLoginRequest = createAsyncThunk(
  'users/sendLoginRequest',
  async (data, { rejectWithValue }) => {
    try {
      const response = await usersAPI.sendLoginRequest(data);
      return response.data;
    } catch (err) {
      Sentry.captureException(err);
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const getCurrentUser = createAsyncThunk(
  'users/getCurrentUser',
  async (refresh) => {
    const response = await usersAPI.getCurrentUser({refresh: refresh});
    posthog.identify(response.data._id);
    posthog.group('team', `id:${response.data.teamId}`);
    posthog.capture('get current user', {
      $set: {
        _id: response.data._id,
        teamId: response.data.teamId,
        timeCreated: response.data.timeCreated,
        email: response.data.email,
        conversionPath: response.data.flowState?.conversion?.path,
        conversionPlan: response.data.flowState?.conversion?.plan
      }
    });
    
    if (process.env.NODE_ENV === 'production') {
      Sentry.setUser({
        id: response.data._id,
        email: response.data.email
      });
      LogRocket.identify(response.data._id, {
        name: response.data.name,
        email: response.data.email,
        teamId: response.data.teamId
      });
    }
    return response.data;
  }
);

export const sendInvite = createAsyncThunk(
  'users/sendInvite',
  async (data, { rejectWithValue }) => {
    try {
      const response = await usersAPI.sendInvite(data);
      posthog.capture('send invite', {email: data.email});
      return response.data;
    } catch (err) {
      Sentry.captureException(err);
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const usersSlice = createSlice({
  name: 'usersSlice',
  initialState: {
    user: null,
    loggedIn: null,
    inviteRequestState: null,
    inviteRequestErrorMessage: null,
    conversionMessage: null
  },
  reducers: {
    setLoggedIn: (state, action) => {
      state.loggedIn = action.payload;
    },
    clearInviteRequestState: (state, _) => {
      state.inviteRequestState = null;
      state.inviteRequestErrorMessage = null;
    },
    showConversionMessage: (state, action) => {
      state.conversionMessage = action.payload;
    },
    hideConversionMessage: (state, _) => {
      state.conversionMessage = null;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(completeAuthWithToken.fulfilled, (state, action) => {
      state.user = action.payload;
      state.loggedIn = true;
    });
    builder.addCase(getCurrentUser.fulfilled, (state, action) => {
      state.user = action.payload;
      state.loggedIn = true;
    });
    builder.addCase(getCurrentUser.rejected, (state) => {
      state.loggedIn = false;
    });
    builder.addCase(sendInvite.fulfilled, (state) => {
      state.inviteRequestState = 'fulfilled';
    });
    builder.addCase(sendInvite.rejected, (state, action) => {
      state.inviteRequestState = 'rejected';
      state.inviteRequestErrorMessage = action.payload.message;
    });
    builder.addCase(sendInvite.pending, (state) => {
      state.inviteRequestState = 'pending';
    });
  }
});

export const { setLoggedIn,
               clearCreateUserWithCompanyState,
               clearInviteRequestState,
               showConversionMessage,
               hideConversionMessage } = usersSlice.actions;

export default usersSlice.reducer;