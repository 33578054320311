import React from 'react';
import * as d3 from 'd3';
import { LineChartCell } from '../../components/LineChartCell';
import { BridgeExplorer } from '../../components/BridgeExplorer';
import { annotateRowsWithStartIndices, generateBridgeData, groupInputData } from '../../services/calculations';
import posthog from 'posthog-js'
import { useHistory } from 'react-router-dom';

function Dashboard(props) {
  const history = useHistory();
  let inputData;
  let convertedData;
  let bridgeData;
  
  if (props.company) {
    inputData = props.company.lastUpload.data;
    convertedData = props.convertedData;
    posthog.capture('view dashboard', {companyId: props.company._id});
  }
  if (props.convertedData) {
    convertedData = Object.assign({}, convertedData);
    convertedData = groupInputData(convertedData, 'Customer');
    convertedData = annotateRowsWithStartIndices(convertedData);
  }
  if (inputData) {
    bridgeData = generateBridgeData(inputData, 'Customer', props.company?.settings?.fiscalYearEnd, props.company?.settings?.convertToQuarters);
    if (props.company._id === 'upload' && props.showDataConfirmation === null) {
      props.setShowDataConfirmation(true);
    }
  }

  return (
    <div className='w-full'>
      {props.showDataConfirmation && <div className='w-full flex flex-wrap px-24'>
        <div className='w-full flex border rounded-lg p-5 flex-wrap mt-5 mb-5'>
          <div className='w-full font-bold'>
            Does your data look correct?
          </div>
          <div className='w-full mt-5'>
            ARR: {`$${d3.format('.1f')(bridgeData[bridgeData.length-1].eop/1000000)}m`}
            <ul className='list-disc pl-6 text-gray-500 text-sm mb-5'>
              <li>1/12 too small? You're uploading MRR. Multiply values by 12 and reupload.</li>
              <li>2x too big? Make sure you're not uploading a SUM line at the bottom.</li>
            </ul>
            Logos: {`${d3.format(',')(bridgeData[bridgeData.length-1].eopLogos)}`}
            <ul className='list-disc pl-6 text-gray-500 text-sm'>
              <li>Too few? Make sure you have customer names/IDs on the far left column (column A) in your input data.</li>
            </ul>
          </div>
          <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
            <button
              type="button"
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
              onClick={() => props.setShowDataConfirmation(false)}
            >
              Looks Good
            </button>
            <button
              type="button"
              className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
              onClick={() => history.push('/upload?reupload=true')}
            >
              Reupload
            </button>
          </div>
        </div>
      </div>}
      <div className='text-2xl mb-10 mt-5 w-full flex'>Overview</div>
      <div className='w-full flex flex-wrap'>
        { bridgeData && <>
        <LineChartCell
          title='Gross Retention'
          valueFormat={d3.format('0.0%')}
          maxBoundY={1}
          minBoundY={0}
          periodLength={convertedData.periodLength}
          headerRef={props.headerRef}
          pairs={bridgeData.map(d => {
            return {
              date: new Date(d.date),
              value: d.LTMgross
            }
          })}
        />
        <LineChartCell
          title='Net Retention'
          valueFormat={d3.format('0.0%')}
          maxBoundY={2}
          minBoundY={0}
          periodLength={convertedData.periodLength}
          headerRef={props.headerRef}
          pairs={bridgeData.map(d => {
            return {
              date: new Date(d.date),
              value: d.LTMnet
            }
          })}
        />
        <LineChartCell
          title='ACV'
          valueFormat={(v) => `$${d3.format('.1f')(v/1000)}k`}
          showRate={true}
          periodLength={convertedData.periodLength}
          headerRef={props.headerRef}
          pairs={bridgeData.map(d => {
            return {
              date: new Date(d.date),
              value: d.dollarsPerAll
            }
          })}
        />
        <LineChartCell
          title='Logos'
          valueFormat={(v) => d3.format(',')(v)}
          showRate={true}
          periodLength={convertedData.periodLength}
          headerRef={props.headerRef}
          pairs={bridgeData.map(d => {
            return {
              date: new Date(d.date),
              value: d.eopLogos
            }
          })}
        />
        <LineChartCell
          title='ARR'
          valueFormat={(v) => `$${d3.format('.1f')(v/1000000)}m`}
          showRate={true}
          periodLength={convertedData.periodLength}
          headerRef={props.headerRef}
          pairs={bridgeData.map(d => {
            return {
              date: new Date(d.date),
              value: d.eop
            }
          })}
        />
        </>}
      </div>
      <div className='text-2xl mb-10 mt-14 w-full'>Bridge</div>
      { bridgeData && convertedData &&
      <BridgeExplorer
          convertedData={convertedData}
          bridgeData={bridgeData}
          headerRef={props.headerRef}
        />
      }
    </div>
  );
}

export default Dashboard;
