import React, { useEffect, useState } from 'react';
import { useRef } from 'react';
import { LineCompsCellSVG } from './LineCompsCellSVG';

export function LineCompsCell(props) {
  const ref = useRef(null);
  if (props.companies && props.companies.length > 0) {
    
    useEffect(() => {
      let el = LineCompsCellSVG(props.companies, {
        width: 615,
        height: 350
      });
      ref.current.innerHTML = '';
      ref.current.appendChild(el);
    }, [props.companies, ref.current])
  }

  return (
    <div className='box-width-half box-tall'>
      <div className='ml-10'>{props.title}</div>
      { props.companies && <>
      <div className=''>
        <div style={{height: '350px'}} ref={ref}></div>
      </div>
      </> }
    </div>
  );
}