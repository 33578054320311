import axios from 'axios';

const companiesAPI = {
  getCompanyById: async (id) => {
    return await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/companies/${id}`,
      headers: {
        'Content-Type': 'application/json'
      },
      withCredentials: true
    })
  },
  updateCompany: async (data) => {
    return await axios({
      method: 'put',
      url: `${process.env.REACT_APP_API_URL}/companies/${data._id}`,
      data: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json'
      },
      withCredentials: true
    })
  },
  deleteCompany: async (companyId) => {
    return await axios({
      method: 'delete',
      url: `${process.env.REACT_APP_API_URL}/companies/${companyId}`,
      headers: {
        'Content-Type': 'application/json'
      },
      withCredentials: true
    })
  },
  searchCompanies: async (query) => {
    return await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/companies/search`,
      params: {query: query},
      headers: {
        'Content-Type': 'application/json'
      },
      withCredentials: true
    })
  },
  getRecentCompanies: async () => {
    return await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/companies`,
      headers: {
        'Content-Type': 'application/json'
      },
      withCredentials: true
    })
  },
  createCompanyWithUpload: async (data) => {
    // {name: xxx, data: xxx}
    return await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/companies`,
      data: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json'
      },
      withCredentials: true
    })
  },
  getCompDataForCompany: async (id) => {
    return await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/companies/${id}/compdata`,
      headers: {
        'Content-Type': 'application/json'
      },
      withCredentials: true
    })
  },
}

export default companiesAPI;