import { showConversionMessage } from "../data/users/slice";
import store from "../data/store";

export const checkPermissionWithMessage = (user, action) => {
  let result = checkPermission(user, action);
  if (result === 'allowed') {
    return true;
  }

  store.dispatch(showConversionMessage({
    action: action,
    permissionResult: result
  }));

  return false;
}

export const checkPermission = (user, action) => {
  if (user) {
    if (user.stripe && user.stripe.status === 'paid') {
      if (user.stripe.plan === 'partner') {
        return 'allowed';
      } else if (user.stripe.plan === 'associate' && action === 'comps') {
        return 'upgrade required'
      } else if (user.stripe.plan === 'associate') {
        return 'allowed';
      }
    } else {
      if (!trialIsExpired(user) || (action !== 'comps' && action !== 'download')) {
        return 'allowed';
      } else {
        return 'plan required';
      }
    }
  }

  return 'signup required';
}

export const trialIsExpired = (user) => {
  if ((new Date(user?.flowState?.trialEndTime)).getTime() > (new Date()).getTime()) {
    return false;
  }
  return true;
}

export const daysUntilTrialExpiry = (user) => {
  let d = Math.ceil(((new Date(user.flowState.trialEndTime)).getTime() - (new Date()).getTime()) / (1000 * 3600 * 24));
  if (d === 1) {
    return '1 day';
  }
  return `${d} days`;
}