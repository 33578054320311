import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import { SearchWidget } from '../../components/SearchWidget';
import { useDispatch, useSelector } from 'react-redux';
import { getCompDataForCompany } from '../../data/companies/slice';
import posthog from 'posthog-js'
import { checkPermissionWithMessage } from '../../services/auth';
import { LineCompsCell } from '../../components/LineCompsCell';
import { getCohortCurvePoints } from '../../services/calculations';

function Comps(props) {
  const dispatch = useDispatch();
  const [companies, setCompanies] = useState([]);
  const addedIds = useRef([]); // Using ref to store data because for some reason state won't update when checking what companies have been added
  const [alignmentType, setAlignmentType] = useState('Most Recent');
  var user;
  var loggedIn;
  if (props.live) {
    user = useSelector((state) => state.usersSlice.user);
    loggedIn = useSelector((state) => state.usersSlice.loggedIn);
  }

  const fieldList = [
    {name: 'ARR',        key: 'eop',       valueFormat: (v) => `$${d3.format('.1f')(v/1000000)}m`},
    {name: 'LTM Growth', key: 'LTMgrowth', valueFormat: d3.format('0.0%')},
    {name: 'ACV (Total)', key: 'dollarsPerAll', valueFormat: (v) => `$${d3.format('.1f')(v/1000)}k`},
    {name: 'Gross Ret', key: 'LTMgross', valueFormat: d3.format('0.0%')},
    {name: 'Net Ret', key: 'LTMnet', valueFormat: d3.format('0.0%')},
  ];

  if (props.company && companies.length === 0) {
    if (props.live) {
      posthog.capture('view comps', {companyId: props.company._id});
    }
    setCompanies([{
      _id: props.company._id,
      name: props.company.name ? props.company.name : 'Unnamed Company',
      bridgeData: props.company.bridgeData,
      netPoints: props.company.netPoints,
      logoPoints: props.company.logoPoints,
      closestARRIndex: props.company.bridgeData.length-1
    }]);
  }

  useEffect(() => {
    addedIds.current = companies.map(c => c._id);
  }, [companies]);

  const handleSearchSelection = async (company) => {
    if (props.live) {
      posthog.capture('add company to comps', {companyId: props.company._id, addedCompanyId: company._id});
      
      try {
        let compData = await dispatch(getCompDataForCompany(company._id)).unwrap();
        setCompanies([...companies, {
          ...company,
          ...compData,
          closestARRIndex: getClosestIndex(compData.bridgeData, 'eop')
        }]);
      } catch (e) { }
    } else {
      setCompanies([...companies, {
        ...company,
        closestARRIndex: getClosestIndex(company.bridgeData, 'eop')
      }]);
    }
  }

  const getClosestIndex = (bridgeData, key) => {
    let targetValue = companies[0].bridgeData.slice(-1)[0][key];
    let closestIndex = bridgeData.length-1;
    let closestDelta = Math.abs(bridgeData[closestIndex][key]-targetValue);
    for (let i=bridgeData.length-2; i>=0; i--) {
      let v = bridgeData[i][key];
      if (typeof(v) === 'number' && !isNaN(v) && Math.abs(v) !== Infinity) {
        let newDelta = Math.abs(v-targetValue);
        if (newDelta < closestDelta) {
          closestDelta = newDelta;
          closestIndex = i;
        }
      }
    }
    return closestIndex;
  }

  const showInResults = (c) => {
    // For search widget to check if company has already been added
    return !addedIds.current.includes(c._id);
  }

  if (props.live) {
    useEffect(() => {
      if (loggedIn !== null) {
        // Make sure login check request is done before trying to show a conversion modal
        checkPermissionWithMessage(user, 'comps');
      }
    }, [loggedIn]);
  }
  
  return (
    <div className='w-full'>
      {props.live && <div className='text-2xl mb-10 mt-5'>Comps</div>}
      {companies && companies.length > 0 && <>
      <div className='w-full flex mb-4'>
        <SearchWidget
          containerClassName='relative max-w-lg w-full lg:max-w-xs'
          handleSelection={handleSearchSelection}
          placeholder={'Add Company'}
          noResultsMessage={'No other companies found'}
          showInResults={showInResults}
          live={props.live}
          compsAvailable={props.compsAvailable} />
        <span className='relative z-0 inline-flex shadow-sm rounded-md ml-6'>
          <button
            type='button'
            className={`${alignmentType === 'Most Recent' ? 'bg-gray-200 cursor-default' : 'bg-white hover:bg-gray-50'} relative inline-flex items-center px-4 py-1 rounded-l-md border border-gray-300 text-sm font-medium text-gray-700 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500`}
            onClick={alignmentType !== 'Most Recent' ? () => setAlignmentType('Most Recent') : () => {}}
          >
            Most Recent
          </button>
          <button
            type='button'
            className={`${alignmentType === 'ARR Aligned' ? 'bg-gray-200 cursor-default' : 'bg-white hover:bg-gray-50'} -ml-px relative inline-flex items-center px-4 py-1 rounded-r-md border border-gray-300 text-sm font-medium text-gray-700 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500`}
            onClick={alignmentType !== 'ARR Aligned' ? () => setAlignmentType('ARR Aligned') : () => {}}
          >
            ARR Aligned
          </button>
        </span>
      </div>
      <div className=''>
      <div className='-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
        <div className='py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8'>
          <div className='shadow overflow-hidden border-b border-gray-200 sm:rounded-lg'>
            <table className='min-w-full divide-y divide-gray-200'>
              <thead className='bg-gray-50'>
                <tr>
                  <th
                    scope='col'
                    className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                  >
                    Name
                  </th>
                  <th
                    scope='col'
                    className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                  >
                    Date
                  </th>
                  {fieldList.map(f => {
                  return <th
                      key={f.key}
                      scope='col'
                      className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                    >
                      {f.name}
                    </th>
                  })}
                </tr>
              </thead>
              <tbody>
                {companies.map(c => {
                  let bridgeSlice;
                  if (alignmentType === 'Most Recent') {
                    bridgeSlice = c.bridgeData.slice(-1)[0];
                  } else if (alignmentType === 'ARR Aligned') {
                    bridgeSlice = c.bridgeData[c.closestARRIndex];
                  }
                  return <tr key={c._id}>
                    <td className='px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900'>{c.name}</td>
                    <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-500'>{d3.utcFormat('%b %Y')(new Date(bridgeSlice.date))}</td>
                    {fieldList.map(f => {
                      return <td key={f.key} className='px-6 py-4 whitespace-nowrap text-sm text-gray-500'>
                        {f.valueFormat(bridgeSlice[f.key])}
                      </td>
                    })}
                  </tr>
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div className='text-2xl mb-10 mt-10'>Average Cohort Retention</div>
    <div className='mb-24'>
      <div className='-my-2 sm:-mx-6 lg:-mx-8'>
        <div className='py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8 flex'>
          <LineCompsCell
            title='Net Cohort Retention'
            companies={companies.map(c => { return {_id: c._id, name: c.name, points: c.netPoints}})}
            />
          <LineCompsCell
            title='Logo Cohort Retention'
            companies={companies.map(c => { return {_id: c._id, name: c.name, points: c.logoPoints}})}
            />
        </div>
      </div>
    </div></>}
    </div>
  );
}

export default Comps;
