import { Fragment, useEffect, useState } from 'react';
import { Dialog, Switch, Transition } from '@headlessui/react';
import { useDispatch } from 'react-redux';
import { updateCompany, updateCompanyInLocalStore } from '../../data/companies/slice';

const fyeRef = {
  Jan: 1,
  Feb: 2,
  Mar: 3,
  Apr: 4,
  May: 5,
  Jun: 6,
  Jul: 7,
  Aug: 8,
  Sep: 9,
  Oct: 10,
  Nov: 11,
  Dec: 12,
}

const fyeRefReverse = {
  1: 'Jan',
  2: 'Feb',
  3: 'Mar',
  4: 'Apr',
  5: 'May',
  6: 'Jun',
  7: 'Jul',
  8: 'Aug',
  9: 'Sep',
  10: 'Oct',
  11: 'Nov',
  12: 'Dec',
}

export default function SettingsModal(props) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [inputsState, setInputsState] = useState({});
  const [convertDisabled, setConvertDisabled] = useState(false);
  
  useState(() => {
    let startState = {};
    if (props.company && props.originalPeriodLength === 3) {
      setConvertDisabled(true);
      startState = {
        convertToQuarters: false,
        fiscalYearEnd: props.company.settings?.fiscalYearEnd ? props.company.settings.fiscalYearEnd : 12
      };
    } else if (props.company && props.originalPeriodLength === 1) {
      startState = {
        convertToQuarters: props.company.settings?.convertToQuarters ? props.company.settings.convertToQuarters : false,
        fiscalYearEnd: props.company.settings?.fiscalYearEnd ? props.company.settings.fiscalYearEnd : 12
      };
    }
    if (props.company?.settings?.maxGapFixMonths) {
      startState.maxGapFixMonths = props.company.settings.maxGapFixMonths;
    } else {
      startState.maxGapFixMonths = 0;
    }
    setInputsState(startState);
  }, [props.company]);
  
  const handleInputChange = (e) => {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    setInputsState({
      ...inputsState,
      [name]: value
    });
  }

  const classNames = (...classes) => {
    return classes.filter(Boolean).join(' ')
  }

  const handleSubmit = async () => {
    let newCo = {...props.company, settings: inputsState};
    if (props.company._id === 'upload') {
      dispatch(updateCompanyInLocalStore(newCo));
    } else {
      setLoading(true);
      await dispatch(updateCompany(newCo)).unwrap();
      setLoading(false);
    }
    props.setOpen(false);
  }

  const handleClose = () => {
    props.setOpen(false);
  }

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={handleClose}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <span>
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div>
                  <div className="mt-3 sm:mt-5">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                      Settings
                    </Dialog.Title>
                    <div className="mt-5 mx-10 grid grid-cols-2 gap-3">
                      <div className='pb-2'>
                        <label htmlFor="convertToQuarters" className="block text-sm font-medium text-gray-700 mb-2">
                          Convert To Quarters
                        </label>
                        <Switch
                          id='convertToQuarters'
                          checked={inputsState.convertToQuarters ? inputsState.convertToQuarters : false}
                          onChange={convertDisabled ? () => {} : (checked) => handleInputChange({target: {name: 'convertToQuarters', type: 'checkbox', checked: checked}})}
                          className={classNames(
                            inputsState.convertToQuarters ? 'bg-indigo-600' : 'bg-gray-200',
                            'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                          )}
                        >
                          <span
                            aria-hidden="true"
                            className={classNames(
                              inputsState.convertToQuarters ? 'translate-x-5' : 'translate-x-0',
                              'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                            )}
                          />
                        </Switch>
                        <div className={`text-xs text-gray-400 mt-2${props.originalPeriodLength === 3 ? '' : ' hidden'}`}>Data is already in quarters.</div>
                      </div>
                      <div>
                        <label htmlFor="fiscalYearEnd" className="block text-sm font-medium text-gray-700">
                          Fiscal Year End
                        </label>
                        <select
                          id="fiscalYearEnd"
                          name="fiscalYearEnd"
                          onChange={(e) => handleInputChange({target: {name: 'fiscalYearEnd', value: fyeRef[e.target.value]}})}
                          className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                          defaultValue={fyeRefReverse[inputsState.fiscalYearEnd]}
                        >
                          {Object.keys(fyeRef).map(k => <option key={k}>{k}</option>)}
                        </select>
                      </div>
                    </div>
                    <div className='pb-2 mx-10 mt-5'>
                        <label htmlFor="convertToQuarters" className="block text-sm font-medium text-gray-700 mb-2">
                          Fix Gaps (Months)
                        </label>
                        <span className="relative z-0 inline-flex shadow-sm rounded-md">
                          <button type="button" onClick={() => handleInputChange({target: {name: 'maxGapFixMonths', value: 0}})} className={`relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 text-sm font-medium text-gray-700 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 ${inputsState.maxGapFixMonths === 0 ? 'bg-gray-100' : 'hover:bg-gray-50 bg-white'}`}>
                            None
                          </button>
                          <button type="button" onClick={() => handleInputChange({target: {name: 'maxGapFixMonths', value: 1}})} className={`-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium text-gray-700 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 ${inputsState.maxGapFixMonths === 1 ? 'bg-gray-100' : 'hover:bg-gray-50 bg-white'}`}>
                            1
                          </button>
                          <button type="button" onClick={() => handleInputChange({target: {name: 'maxGapFixMonths', value: 2}})} className={`-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium text-gray-700 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 ${inputsState.maxGapFixMonths === 2 ? 'bg-gray-100' : 'hover:bg-gray-50 bg-white'}`}>
                            2
                          </button>
                          <button type="button" onClick={() => handleInputChange({target: {name: 'maxGapFixMonths', value: 3}})} className={`-ml-px relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-300 text-sm font-medium text-gray-700 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 ${inputsState.maxGapFixMonths === 3 ? 'bg-gray-100' : 'hover:bg-gray-50 bg-white'}`}>
                            3
                          </button>
                        </span>
                      </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                    onClick={!loading ? handleSubmit : () => {}}
                  >
                    {!loading ? 'Update' : 'Loading...'}
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                    onClick={() => handleClose()}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </span>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}