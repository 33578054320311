import * as d3 from 'd3';

export const MultiLineChartCellSVG = (triples, {
  width = 400,
  height = 174,
  setHoverTriples = undefined,
  maxBoundY = null,
  minBoundY = null,
  legendSpec = {},
  headerRef = {}
} = {}) => {
  const margin = {top: 10, right: 30, bottom: 30, left: 30};

  const xVals = d3.map(triples, (t) => t.date);
  const xValsUnique = Array.from(new Set(xVals)).sort((a, b) => a.getTime() - b.getTime());
  const yVals = d3.map(triples, (t) => t.value);
  
  // X and Y scale
  const xScale = d3.scaleTime()
    .domain(d3.extent(xVals))
    .range([margin.left, width - margin.right]);

  let yExtent = d3.extent(yVals.filter(v => (typeof(v) === 'number' && !isNaN(v) && Math.abs(v) !== Infinity))).reverse();
  let maxY;
  let minY;
  if (maxBoundY) {
    maxY = Math.min(yExtent[0], parseInt(maxBoundY));
  } else {
    maxY = yExtent[0];
  }
  
  if (minBoundY) {
    minY = Math.max(yExtent[1], parseInt(minBoundY));
  } else {
    minY = yExtent[1];
  }
  
  const yScale = d3.scaleLinear()
    .domain([maxY, minY])
    .range([margin.top, height - margin.bottom])

  const line = d3.line()
    .defined(d => (typeof(d.value) === 'number' && !isNaN(d.value) && Math.abs(d.value) !== Infinity))
    .x(d => xScale(d.date))
    .y(d => yScale(d.value));

  const svg = d3.create('svg')
    .attr('width', width)
    .attr('height', height)
    .attr('viewBox', [0, 0, width, height])
    .attr('style', 'max-width: 100%; height: auto; height: intrinsic;')
    .style('-webkit-tap-highlight-color', 'transparent');
  
  svg.append('g')
    .attr('fill', 'none')
    .attr('stroke-linecap', 'butt')
    .attr('stroke-linejoin', 'miter')
    .attr('stroke-width', 1)
    .attr('stroke-opacity', 0.5)
  .selectAll('path')
  .data(d3.group(triples, d => d.segment))
  .join('path')
    .style('mix-blend-mode', 'multiple')
    .attr('stroke', ([segment]) => legendSpec[segment].color)
    .attr('d', ([, _triples]) => line(_triples));

  // Date labels

  svg.append('text')
    .attr('x', margin.left)
    .attr('y', height - margin.bottom + 25)
    .attr('text-anchor', 'start')
    .style('font-size', '10px')
    .text(headerRef[triples[0].date])

  svg.append('text')
    .attr('x', width - margin.right)
    .attr('y', height - margin.bottom + 25)
    .attr('text-anchor', 'end')
    .style('font-size', '10px')
    .text(headerRef[triples[triples.length-1].date])

  // Hover line & catch rect

  svg.append('rect')
    .attr('class', 'hover-line')
    .attr('x', 0)
    .attr('y', margin.top)
    .attr('height', height - margin.bottom - margin.top)
    .attr('width', 2)
    .attr('opacity', 0)
    .attr('fill', 'black')
    
  const catchRect = svg.append('rect')
    .attr('x', 0)
    .attr('y', 0)
    .attr('height', height)
    .attr('width', width)
    .style('fill', '#FFF')
    .attr('opacity', 0)
    .on('mousemove', hoverMove)

  function getRelPos(event, rect, rectMargin) {
    if (event.clientX === 0 || event.clientY === 0) {
      return null // Sometimes we get bad click data w/ click pos x0y0
    }
    return {
      x: event.clientX - rect.left - rectMargin.left,
      y: event.clientY - rect.top - rectMargin.top
    }
  }

  function hoverMove(e) {
    let rect = catchRect.node().getBoundingClientRect();
    let pos = getRelPos(e, rect, margin);
    if (pos) {
      if (rect.width > 0 && rect.height > 0 && (pos.x < 0 || pos.y < 0 || pos.x > rect.width-margin.left-margin.right || pos.y > rect.height-margin.top-margin.bottom)) {
        svg.select('.hover-line')
          .attr('opacity', 0);
        setHoverTriples(null);
      } else if (rect.width > 0 && rect.height > 0) {
        let i = Math.round(xValsUnique.length * pos.x / (width-margin.left-margin.right)) - 1;
        if (i < xValsUnique.length && i >= 0) {
          let targetDate = xValsUnique[i];
          svg.select('.hover-line')
            .attr('x', pos.x + margin.left)
            .attr('opacity', 0.5);
          setHoverTriples(triples.filter(t => t.date.getTime() === targetDate.getTime()));
        }
      }
    }
  }

  const element = svg.node();
  element.value = null;
  return element;
}