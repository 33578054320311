import React, { useState } from 'react';

import DeleteModal from './DeleteModal';

export function DeleteWidget(props) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <div onClick={() => {setOpen(true)}} className={props.buttonClassName}>Delete</div>
      <DeleteModal
        open={open}
        setOpen={setOpen}
        company={props.company}/>
    </>
  );
}