import { Fragment, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, Transition } from '@headlessui/react';
import { clearInviteRequestState, sendInvite } from '../../data/users/slice';
import { CheckIcon } from '@heroicons/react/outline';

export default function ShareModal(props) {
  const dispatch = useDispatch();
  const emailInputRef = useRef(null);
  const [inputsState, setInputsState] = useState({});
  const inviteRequestState = useSelector((state) => state.usersSlice.inviteRequestState);
  const inviteRequestErrorMessage = useSelector((state) => state.usersSlice.inviteRequestErrorMessage);

  const handleInputChange = (e) => {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    setInputsState({
      ...inputsState,
      [name]: value
    });
  }

  const handleSubmit = () => {
    dispatch(clearInviteRequestState());
    dispatch(sendInvite(inputsState));
  }

  const handleClose = () => {
    setInputsState({});
    dispatch(clearInviteRequestState());
    props.setOpen(false);
  }

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={emailInputRef} onClose={handleClose}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <span>
            {inviteRequestState !== 'fulfilled' && <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div>
                <div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                      Invite Your Team
                    </Dialog.Title>
                    <div className="mt-2 mx-10">
                    <p className="text-sm text-gray-500 mt-3">Enter your teammember's email. We'll send them an invite to join your team. This will let them see all the companies in your team's library.</p>
                      <div className="mt-6 relative border border-gray-300 rounded-md px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ring-indigo-600 focus-within:border-indigo-600">
                        <label
                          htmlFor="name"
                          className="absolute -top-2 left-2 -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-900"
                        >
                          Email
                        </label>
                        <input
                          type="email"
                          name="email"
                          autoCapitalize="none"
                          value={inputsState.email || ''}
                          onChange={handleInputChange}
                          id="name"
                          className="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 outline-none  sm:text-sm"
                          placeholder="name@yourcompany.com"
                          ref={emailInputRef}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {inviteRequestErrorMessage && <div className="text-red-700 text-sm mt-2 text-center">{inviteRequestErrorMessage}</div>}
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                    onClick={inviteRequestState !== 'pending' ? handleSubmit : () => {}}
                  >
                    {inviteRequestState !== 'pending' ? 'Send Invite' : 'Loading...'}
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                    onClick={() => handleClose()}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>}
            {inviteRequestState === 'fulfilled' && <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
              <div>
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                  <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                </div>
                <div className="mt-3 text-center sm:mt-5">
                  <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                    Invite sent!
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Tell your teammate to check their email.
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-6">
                <button
                  type="button"
                  className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                  onClick={() => handleClose()}
                >
                  Close
                </button>
              </div>
            </div>}</span>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}