import React, { useState, useRef, useEffect } from 'react';
import { Tooltip } from '../Tooltip';

export function ClickToEditWidget(props) {
  const [isEditing, setIsEditing] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    if (isEditing) {
      if (props.currentValue) {
        inputRef.current.value = props.currentValue;
      }
      inputRef.current.focus();
    }
  }, [isEditing]);

  const handleUpdate = () => {
    setIsEditing(false);
    props.onUpdate(inputRef.current.value);
    inputRef.current.value = '';
  }

  return (
    <>
      <Tooltip content='Click to edit' disabled={props.tooltipDisabled}>
        <div
          className={props.textClassName + `${isEditing ? ' hidden': ''}`}
          onClick={() => setIsEditing(true)}>
          {props.currentValue ? props.currentValue : props.placeholder}
        </div>
      </Tooltip>
      <div className={`relative flex px-2 py-2 mr-10 w-56 ${isEditing ? '': ' hidden'}`}>
        <input
          type="text"
          name="name"
          id="name"
          className="block w-full border-0 p-0 text-gray-900 outline-none ring-0 focus:ring-0 w-36"
          placeholder={props.placeholder}
          ref={inputRef}
          onKeyPress={(e) => {if (e.key === 'Enter') { handleUpdate() }}}
        />
        <button
          type="button"
          className="inline-flex items-center ml-2 px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          onClick={handleUpdate}
        >
          Done
        </button>
      </div>
    </>
  );
}