import React, { useEffect, useState } from 'react';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import posthog from 'posthog-js'
import { XCircleIcon } from '@heroicons/react/solid';

// Data & Processing Utils
import { validateAndParse } from '../services/files';
import { useHistory } from 'react-router';
import { clearLastUploadedId, setShowUploadSuccess, uploadCompanyToLocalStore } from '../data/companies/slice';
import { generateBridgeData, getCohortCurvePoints } from '../services/calculations';

function Upload() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState();

  useEffect(() => {
    dispatch(clearLastUploadedId());
    dispatch(setShowUploadSuccess(false)); // May be a better way to do this; adding this line to make sure notification doesn't show from last save
    posthog.capture('view upload');
  }, []);

  const onDrop = useCallback(async acceptedFiles => {
    setErrorMessage();
    let result = await validateAndParse(acceptedFiles);
    if (result.success) {
      dispatch(uploadCompanyToLocalStore({
        _id: 'upload',
        name: null,
        lastUpload: {
          data: result.data
        },
        bridgeData: generateBridgeData(result.data, 'Customer'),
        netPoints: getCohortCurvePoints(result.data, 'net'),
        logoPoints: getCohortCurvePoints(result.data, 'logo')
      }))
      posthog.capture('upload company');
      history.push('/company/dashboard?companyId=upload');
    } else {
      setErrorMessage(result.message);
    }
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({onDrop});

  return (
    <div className='w-full pl-4'>
      <div className='text-2xl mb-10 mt-5'>Upload</div>
      {errorMessage && 
        <div className='rounded-md bg-red-50 p-4 mb-10'>
          <div className='flex'>
            <div className='flex-shrink-0'>
              <XCircleIcon />
            </div>
            <div className='ml-3'>
              <h3 className='text-sm font-medium text-red-800'>
                File upload error
              </h3>
              <div className='mt-2 text-sm text-red-700'>
                <div>{errorMessage}</div>
                <div className='mt-5'>Can't figure it out? Email us at <a href='mailto:hello@vfinal.io'>hello@vfinal.io</a> and we'll email back ASAP to help you.</div>
              </div>
            </div>
          </div>
        </div>
      }
      <div {...getRootProps()} className='cursor-pointer border p-10 text-lg font-bold'>
        <input {...getInputProps()} />
        {
          <>
            {isDragActive ? <p>Drop the CSV here ...</p>  : <p>Drop your CSV file here (ARR not MRR), or click to select it</p>}
            <img
              className='pr-96 pt-10 pb-5 pl-10'
              src={`${process.env.PUBLIC_URL}/images/upload-instructions.png`}
              alt=''
            />
            <div className='mt-6'>How do I make a CSV from an XLSX file?
            <ol className='text-sm font-normal list-decimal pl-8 pt-2'>
              <li><b>File -> Save As</b></li>
              <li><b>Browse</b></li>
              <li>Under <b>Save as type</b> box, select <b>CSV (Comma delimited)</b></li>
              <li>Click <b>Save</b>, then upload this file to vFinal</li>
            </ol>
            </div>
          </>
        }
      </div>
    </div>
  );
}

export default Upload;
