import React, { useState } from 'react';
import posthog from 'posthog-js'
import { CustomersExplorer } from '../../components/CustomersExplorer';
import { annotateRowsWithStartIndices, groupInputData } from '../../services/calculations';
import { EventsExplorer } from '../../components/EventsExplorer';

const rangeMonthOptions = {
  'Last 3 Months': 3,
  'Last 6 Months': 6,
  'Last 12 Months': 12,
  'Last 24 Months': 24,
};

function Customers(props) {
  const [rangeMonths, setRangeMonths] = useState(12);
  let convertedData;
  if (props.company) {
    convertedData = props.convertedData;
    posthog.capture('view customers', {companyId: props.company._id});
  }

  if (convertedData) {
    convertedData = Object.assign({}, convertedData);
    convertedData = groupInputData(convertedData, 'Customer');
    convertedData = annotateRowsWithStartIndices(convertedData);
  }

  return (
    <div className='w-full'>
      <div className='text-2xl mb-6 mt-5 w-full'>Top Customers</div>
      { convertedData &&
      <CustomersExplorer
          inputData={convertedData}
          headerRef={props.headerRef}
        />
      }
      <div className='w-full flex mb-10 mt-5'>
        <div className='text-2xl'>Top Events</div>
        <div className='ml-12'>
        <select
          name='key'
          className='mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none sm:text-sm rounded-md'
          onChange={(e) => setRangeMonths(rangeMonthOptions[e.target.value])}
          defaultValue={'Last 12 Months'}
        >
          {Object.keys(rangeMonthOptions).map(monthOption => {
            return <option key={monthOption}>{monthOption}</option>
          })}
        </select>
      </div>
      </div>
      { convertedData &&
        <EventsExplorer
          inputData={convertedData}
          rangeMonths={rangeMonths}
          headerRef={props.headerRef}
        />
      }
    </div>
  );
};

export default Customers;