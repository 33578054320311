import React, { Fragment, useEffect, useRef, useState } from 'react';
import { SearchIcon } from '@heroicons/react/solid';
import { Listbox, Transition } from '@headlessui/react'
import { useDispatch } from 'react-redux';
import { searchCompanies } from '../../data/companies/slice';


export function SearchWidget(props) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const inputRef = useRef(null);
  const containerRef = useRef(null);
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleInputChange = async (e) => {
    let query = e.target.value;
    if (query.length === 0) {
      setOpen(false);
      populateNoQueryResults();
    } else {
      setLoading(true);
      if (!open) setOpen(true);
      let data;
      if (props.live) {
        data = await dispatch(searchCompanies(query)).unwrap();
      } else {
        data = props.compsAvailable.filter(comp => comp.name.toLowerCase().includes(query.toLowerCase()));
      }
      if (props.showInResults) {
        data = data.filter(props.showInResults);
      }
      setSearchResults(data);
      setLoading(false);
    }
  }

  const handleSelection = (company) => {
    setTimeout(() => setSearchResults([]), 250);
    inputRef.current.value = '';
    setOpen(false);
    props.handleSelection(company);
  }

  const populateNoQueryResults = () => {
    // Generates results shown before user types a query
    if (inputRef.current.value.length > 0) return;
    if (props.live) {
      setSearchResults([]);
    } else {
      let data = props.compsAvailable.filter(props.showInResults);
      setSearchResults(data);
    }
  }

  const handleClick = (e) => {
    if (containerRef.current) {
      if (!containerRef.current.contains(e.target)) {
        setOpen(false);
      } else if (inputRef.current.contains(e.target)) {
        setOpen(true);
        if (inputRef.current.value.length === 0) {
          populateNoQueryResults();
        }
      }
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClick);
    };
  }, [containerRef]);

  return (
    <>
      <Listbox onChange={handleSelection}>
      {() => (
        <>
          <div className={props.containerClassName} ref={containerRef}>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </div>
              <input
                id="search"
                name="search"
                className="block w-full pl-10 pr-3 py-1 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                placeholder={props.placeholder}
                type="search"
                onChange={handleInputChange}
                ref={inputRef}
              />
            </div>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
              { loading && <div className='w-full select-none relative py-2 pl-3 pr-9 font-normal'>Loading...</div>}
              { !loading && searchResults.length === 0 && <div className='w-full select-none relative py-2 pl-3 pr-9 font-normal'>{props.noResultsMessage ? props.noResultsMessage : 'No companies found'}</div>}
              { !loading &&  <>
                {searchResults.map((company) => (
                  <Listbox.Option
                    key={company._id}
                    className='cursor-pointer select-none relative py-2 pl-3 pr-9 hover:bg-gray-200 vf-search-lbo'
                    value={company}
                  >
                    {() => (
                      <>
                        <span className='font-normal'>
                          {company.name}
                        </span>
                      </>
                    )}
                  </Listbox.Option>
                ))}
                </>}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
    </>
  );
}