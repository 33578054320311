import React, { useEffect, useState } from 'react';
import { useRef } from 'react';
import * as d3 from 'd3';
import { LineChartCellSVG } from './LineChartCellSVG';

export function LineChartCell(props) {
  const ref = useRef(null);
  const [hoverValue, setHoverValue] = useState(null);
  const [hoverRate, setHoverRate] = useState(null);
  const [hoverDate, setHoverDate] = useState(null);

  let primaryValue = props.pairs[props.pairs.length-1].value;
  if (hoverValue) {
    primaryValue = hoverValue;
  }
  primaryValue = props.valueFormat(primaryValue);

  let rate;
  if (props.showRate) {
    rate = getLTMChangeRate(props.pairs.length-1);
    if (hoverRate) {
      rate = hoverRate;
    }
    if (rate != null) {
      rate = (rate > 0 ? '+' : '-') + d3.format('0.0%')(Math.abs(rate)) + ' y/y';
    }
  }
  
  useEffect(() => {
    if (props.pairs) {
      if (ref.current) {
        let el = LineChartCellSVG(props.pairs, {
          height: 174,
          width: 300,
          maxBoundY: props.maxBoundY,
          minBoundY: props.minBoundY,
          headerRef: props.headerRef,
          setHoverData: (d, i) => {
            setHoverValue(d.value);
            setHoverDate(d.date ? props.headerRef[d.date] : '');
            if (props.showRate) {
              if (i != null) {
                setHoverRate(getLTMChangeRate(i));
              } else {
                setHoverRate(null);
              }
            }
          }
        })
        ref.current.innerHTML = '';
        ref.current.appendChild(el);
      }
    }}, [props.pairs]);

  function getLTMChangeRate (i) {
    let priorYearIndex = i-12/props.periodLength;
    if (priorYearIndex >= 0) {
      return parseFloat(props.pairs[i].value) / parseFloat(props.pairs[priorYearIndex].value) - 1;
    }
    return null;
  }
  
  return (
    <div className='box-width-qtr'>
      <div><a href='/blog/metrics' target='_blank'>{props.title}</a></div>
      { props.pairs && <>
      <div className='flex'>
        <div className='text-2xl mr-2'>{primaryValue}</div>
        { props.showRate && rate &&
        <div className='text-sm text-gray-400 align-text-bottom mr-2' style={{marginTop: 'auto', paddingBottom: '2px'}}>{rate}</div>
        }
        <div className='text-sm text-gray-400 align-text-bottom' style={{marginTop: 'auto', paddingBottom: '2px'}}>{hoverDate}</div>
      </div>
      <div className='w-full' style={{height: '174px'}} ref={ref}></div>
      </> }
    </div>
  );
}