/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from 'react'
import { CheckIcon, MinusIcon } from '@heroicons/react/solid'

const tiers = [
  { name: 'Free',
    action: 'Create an Account',
    buttonStyle: 'bg-gray-800 border-gray-800 hover:bg-gray-900',
    href: '/signup?path=pricing&plan=free',
    priceMonthly: 0,
    description: 'See metrics, cohorts, segmentation, and save and share companies with your team' },
  {
    name: 'Associate',
    action: 'Try for Free',
    buttonStyle: 'bg-indigo-600 border-indigo-600 hover:bg-indigo-700',
    href: '/signup?path=pricing&plan=associate',
    priceMonthly: 49,
    description: 'Download the analyses to a flowing Excel file; build your own outputs',
  },
  {
    name: 'Partner',
    action: 'Try for Free',
    buttonStyle: 'bg-gray-800 border-gray-800 hover:bg-gray-900',
    href: '/signup?path=pricing&plan=partner',
    priceMonthly: 99,
    description: 'Benchmark across every company in your library',
  },
]
const sections = [
  {
    name: 'Instant Analytics',
    features: [
      { name: 'ARR, ACV, Growth, and Retention', tiers: { Free: true, Associate: true, Partner: true } },
      { name: 'Cohort Charts', tiers: { Free: true, Associate: true, Partner: true } },
      { name: 'Segmentation', tiers: { Free: true, Associate: true, Partner: true } },
    ],
  },
  {
    name: 'Advanced Features',
    features: [
      { name: 'Download to Excel', tiers: { Free: false, Associate: true, Partner: true } },
      { name: 'Benchmarking', tiers: { Free: false, Associate: false, Partner: true } },
    ],
  }
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Pricing() {
  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto bg-white py-16 sm:py-24 sm:px-6 lg:px-8">
        <div className="relative z-10 text-center text-gray-800 max-w-2xl px-4 sm:px-3 mx-auto pt-16 sm:pt-18 xl:pt-24 pb-16 sm:pb-24 md:pb-20 xl:pb-28">
          <h1 class="text-4xl sm:text-5.5xl font-extrabold tracking-tight mb-4">Turn that 4 hours into 15 seconds</h1>
          <p class="text-lg max-w-xl mx-auto mb-8">10:1 ROI on your time, flawless work, and immediate insights</p>
        </div>
        {/* xs to lg */}
        <div className="max-w-2xl mx-auto space-y-16 lg:hidden">
          {tiers.map((tier, tierIdx) => (
            <section key={tier.name}>
              <div className="px-4 mb-8">
                <h2 className="text-lg leading-6 font-medium text-gray-900">{tier.name}</h2>
                <p className="mt-4">
                  <span className="text-4xl font-extrabold text-gray-900">${tier.priceMonthly}</span>{' '}
                  <span className="text-base font-medium text-gray-500">/user/mo</span>
                </p>
                <p className="mt-4 text-sm text-gray-500">{tier.description}</p>
                <a
                  href={tier.href}
                  className={`mt-6 block border rounded-md w-full py-2 text-sm font-semibold text-white text-center ${tier.buttonStyle}`}
                >
                  {tier.action}
                </a>
              </div>

              {sections.map((section) => (
                <table key={section.name} className="w-full">
                  <caption className="bg-gray-50 border-t border-gray-200 py-3 px-4 text-sm font-medium text-gray-900 text-left">
                    {section.name}
                  </caption>
                  <thead>
                    <tr>
                      <th className="sr-only" scope="col">
                        Feature
                      </th>
                      <th className="sr-only" scope="col">
                        Included
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {section.features.map((feature) => (
                      <tr key={feature.name} className="border-t border-gray-200">
                        <th className="py-5 px-4 text-sm font-normal text-gray-500 text-left" scope="row">
                          {feature.name}
                        </th>
                        <td className="py-5 pr-4">
                          {typeof feature.tiers[tier.name] === 'string' ? (
                            <span className="block text-sm text-gray-700 text-right">{feature.tiers[tier.name]}</span>
                          ) : (
                            <>
                              {feature.tiers[tier.name] === true ? (
                                <CheckIcon className="ml-auto h-5 w-5 text-green-500" aria-hidden="true" />
                              ) : (
                                <MinusIcon className="ml-auto h-5 w-5 text-gray-400" aria-hidden="true" />
                              )}

                              <span className="sr-only">{feature.tiers[tier.name] === true ? 'Yes' : 'No'}</span>
                            </>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ))}

              <div
                className={classNames(
                  tierIdx < tiers.length - 1 ? 'py-5 border-b' : 'pt-5',
                  'border-t border-gray-200 px-4'
                )}
              >
                <a
                  href={tier.href}
                  className={`block w-full border rounded-md py-2 text-sm font-semibold text-white text-center ${tier.buttonStyle}`}
                >
                  {tier.action}
                </a>
              </div>
            </section>
          ))}
        </div>

        {/* lg+ */}
        <div className="hidden lg:block">
          <table className="w-full h-px table-fixed">
            <caption className="sr-only">Pricing plan comparison</caption>
            <thead>
              <tr>
                <th className="pb-4 px-6 text-sm font-medium text-gray-900 text-left" scope="col">
                  <span className="sr-only">Feature by</span>
                  <span>Plans</span>
                </th>
                {tiers.map((tier) => (
                  <th
                    key={tier.name}
                    className="w-1/4 pb-4 px-6 text-lg leading-6 font-medium text-gray-900 text-left"
                    scope="col"
                  >
                    {tier.name}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="border-t border-gray-200 divide-y divide-gray-200">
              <tr>
                <th className="py-8 px-6 text-sm font-medium text-gray-900 text-left align-top" scope="row">
                  Pricing
                </th>
                {tiers.map((tier) => (
                  <td key={tier.name} className="h-full py-8 px-6 align-top">
                    <div className="relative h-full table">
                      <p>
                        <span className="text-4xl font-extrabold text-gray-900">${tier.priceMonthly}</span>{' '}
                        <span className="text-base font-medium text-gray-500">/user/mo</span>
                      </p>
                      <p className="mt-4 mb-16 text-sm text-gray-500">{tier.description}</p>
                      <a
                        href={tier.href}
                        className={`absolute bottom-0 flex-grow block w-full border rounded-md 5 py-2 text-sm font-semibold text-white text-center ${tier.buttonStyle}`}
                      >
                        {tier.action}
                      </a>
                    </div>
                  </td>
                ))}
              </tr>
              {sections.map((section) => (
                <Fragment key={section.name}>
                  <tr>
                    <th
                      className="bg-gray-50 py-3 pl-6 text-sm font-medium text-gray-900 text-left"
                      colSpan={4}
                      scope="colgroup"
                    >
                      {section.name}
                    </th>
                  </tr>
                  {section.features.map((feature) => (
                    <tr key={feature.name}>
                      <th className="py-5 px-6 text-sm font-normal text-gray-500 text-left" scope="row">
                        {feature.name}
                      </th>
                      {tiers.map((tier) => (
                        <td key={tier.name} className="py-5 px-6">
                          {typeof feature.tiers[tier.name] === 'string' ? (
                            <span className="block text-sm text-gray-700">{feature.tiers[tier.name]}</span>
                          ) : (
                            <>
                              {feature.tiers[tier.name] === true ? (
                                <CheckIcon className="h-5 w-5 text-green-500" aria-hidden="true" />
                              ) : (
                                <MinusIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                              )}

                              <span className="sr-only">
                                {feature.tiers[tier.name] === true ? 'Included' : 'Not included'} in {tier.name}
                              </span>
                            </>
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
                </Fragment>
              ))}
            </tbody>
            <tfoot>
              <tr className="border-t border-gray-200">
                <th className="sr-only" scope="row">
                  Choose your plan
                </th>
                {tiers.map((tier) => (
                  <td key={tier.name} className="pt-5 px-6">
                    <a
                      href={tier.href}
                      className={`block w-full border rounded-md py-2 text-sm font-semibold text-white text-center ${tier.buttonStyle}`}
                    >
                      {tier.action}
                    </a>
                  </td>
                ))}
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  )
}
