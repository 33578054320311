import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import PaymentModal from './PaymentModal';
import { hideConversionMessage } from '../../data/users/slice';
import SignupModal from './SignupModal';
import { useHistory, useLocation } from 'react-router-dom';

export function Conversion(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const search = useLocation().search;
  const conversionMessage = useSelector((state) => state.usersSlice.conversionMessage);

  const [paymentModalOpen, setPaymentModalOpen] = useState(false);
  const [signupModalOpen, setSignupModalOpen] = useState(false);

  const handleClose = () => {
    if (conversionMessage.action === 'comps') {
      history.push(`/company/dashboard${search}`);
    }
    dispatch(hideConversionMessage());
  }
  
  useEffect(() => {
    if (!conversionMessage) {
      setSignupModalOpen(false);
      setPaymentModalOpen(false);
      return;
    };
    
    if (conversionMessage.permissionResult === 'allowed') {
      return;
    } else if (conversionMessage.permissionResult === 'signup required') {
      setSignupModalOpen(true);
    } else if (conversionMessage.permissionResult === 'plan required' || conversionMessage.permissionResult === 'upgrade required') {
      setPaymentModalOpen(true);
    }
  }, [conversionMessage]);

  return (
    <> 
      <PaymentModal
        open={paymentModalOpen}
        close={handleClose}
        conversionMessage={conversionMessage}
      />
      <SignupModal
        open={signupModalOpen}
        close={handleClose}
        conversionMessage={conversionMessage}
      />
    </>
  );
}