import React, { useState } from 'react';
import fileDownload from 'js-file-download';
import axios from 'axios';
import posthog from 'posthog-js'
import { useSelector } from 'react-redux';
import { checkPermissionWithMessage } from '../../services/auth';

export function DownloadWidget(props) {
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.usersSlice.user);

  const handleDownload = async () => {
    setLoading(true);
    posthog.capture('download', {companyId: props.company._id});
    let result;
    if (props.company._id !== 'upload') {
      result = await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}/companies/${props.company._id}/download`,
        headers: {
          'Content-Type': 'application/json'
        },
        withCredentials: true,
        responseType: 'blob'
      })
    } else {
      result = await axios({
        method: 'post',
        url: `${process.env.REACT_APP_API_URL}/companies/download`,
        data: {
          data: props.company.lastUpload.data,
          settings: props.company.settings
        },
        headers: {
          'Content-Type': 'application/json'
        },
        withCredentials: true,
        responseType: 'blob'
      })
    }
    fileDownload(result.data, 'vF-Download.xlsx');
    setLoading(false);
  }

  const handleAction = async () => {
    let allowed = checkPermissionWithMessage(user, 'download');
    if (allowed) {
      await handleDownload();
    }
  }

  return (
      <div onClick={loading ? () => {} : handleAction} className={props.buttonClassName}>{loading ? 'Loading...' : 'Download'}</div>
  );
}