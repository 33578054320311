import { useEffect, useState } from 'react';
import posthog from 'posthog-js'
import { annotateRowsWithStartIndices, generateBridgeData, getPeriodHeaderRefFromInputData, groupInputData } from '../../services/calculations';
import * as d3 from 'd3';

const featureDetail = {
  metrics: {
    title: 'Key Metrics',
    detail: 'Hover over the charts to see how the business has evolved',
    componentName: 'LineChartCell'
  },
  download: {
    title: 'Download to Excel',
    detail: 'Download the vFinal excel export, or see what the input file looked like'
  },
  bridge: {
    title: 'Interactive Bridge',
    detail: 'Click a period to investigate the underlying customer trends',
    componentName: 'BridgeExplorer'
  },
  cohorts: {
    title: 'Cohorts',
    detail: 'Discover any unusual cohort trends without spending hours on the analysis',
    componentName: 'CohortGrid'
  },
  segmentation: {
    title: 'Segmentation',
    detail: 'One-click segmentation lets you see the underlying components of growth'
  },
  rollforward: {
    title: 'Customer Concentration & Rollforward',
    detail: 'Click a period to see how customers change over time',
    componentName: 'CustomersExplorer',
  },
  events: {
    title: 'Top Events',
    detail: 'Immediately see the biggest events in the customer base',
    componentName: 'EventsExplorer',
  },
  benchmarking: {
    title: 'Benchmarking',
    detail: 'Compare metrics between all the companies you\'ve seen',
  }
};

Object.keys(featureDetail).map(key => {
  if (featureDetail[key].componentName) {
    import(`../${featureDetail[key].componentName}`).then(m => {
      featureDetail[key].component = m[featureDetail[key].componentName];
    });
  } else if (featureDetail[key].title === 'Segmentation') {
    import(`../../pages/company/segmentation`).then(m => {
      featureDetail[key].component = m.default;
    });
  } else if (featureDetail[key].title === 'Benchmarking') {
    import(`../../pages/company/comps`).then(m => {
      featureDetail[key].component = m.default;
    });
  }
});

const compsAvailable = [
  {_id: 'ABC', name: 'ABC Inc', fileName: 'abc-comp-data.json'},
  {_id: 'DEF', name: 'DEF Inc', fileName: 'def-comp-data.json'},
  {_id: 'XYZ', name: 'XYZ Inc', fileName: 'xyz-comp-data.json'},
];

for (let i=0; i<compsAvailable.length; i++) {
  import(`./${compsAvailable[i].fileName}`).then(compData => {
    compsAvailable[i] = {...compsAvailable[i], ...compData};
  });
}

export default function FeatureShowcase(props) {
  const [company, setCompany] = useState(null);
  const [bridgeData, setBridgeData] = useState(null);
  const [headerRef, setHeaderRef] = useState(null);
  const [groupedData, setGroupedData] = useState(null);

  const Component = props.featureKey ? featureDetail[props.featureKey].component : null;

  useEffect(() => {
    if (props.featureKey) {
      if (company === null) {
        // Load in the company from the JSON file
        import('./test-data.json').then(companyFromFile => {
          setCompany(companyFromFile);
          setHeaderRef(getPeriodHeaderRefFromInputData(companyFromFile.lastUpload.data, companyFromFile.settings));
          let generatedBridgeData = generateBridgeData(companyFromFile.lastUpload.data, 'Customer', companyFromFile?.settings?.fiscalYearEnd, companyFromFile?.settings?.convertToQuarters);
          setBridgeData(generatedBridgeData);
          let structuredInputData = Object.assign({}, companyFromFile.lastUpload.data);
          structuredInputData = groupInputData(structuredInputData, 'Customer');
          setGroupedData(annotateRowsWithStartIndices(structuredInputData));
        });
      }
      posthog.capture('select feature', {featureKey: props.featureKey});
    }
  }, [props.featureKey]);

  return (
    <div className='w-full' style={{minHeight: props.featureKey ? '750px': ''}}>
      <div className='text-xl gray-600 h-6 text-center w-6/12 mx-auto font-medium mt-8 mb-4'>{props.featureKey ? featureDetail[props.featureKey].title : <span className='text-indigo-500 font-bold text-2xl'></span>}</div>
      <div className='text-lg gray-400 h-6 text-center w-6/12 mx-auto mb-16'>{props.featureKey ? featureDetail[props.featureKey].detail : ''}</div>
      
      {props.featureKey === 'metrics' && 
      <div className='w-full flex flex-wrap justify-center'>
        { Component && bridgeData && company && <>
        <Component
          title='Gross Retention'
          valueFormat={d3.format('0.0%')}
          maxBoundY={1}
          minBoundY={0}
          periodLength={company.lastUpload.data.periodLength}
          headerRef={headerRef}
          pairs={bridgeData.map(d => {
            return {
              date: new Date(d.date),
              value: d.LTMgross
            }
          })}
        />
        <Component
          title='Net Retention'
          valueFormat={d3.format('0.0%')}
          maxBoundY={2}
          minBoundY={0}
          periodLength={company.lastUpload.data.periodLength}
          headerRef={headerRef}
          pairs={bridgeData.map(d => {
            return {
              date: new Date(d.date),
              value: d.LTMnet
            }
          })}
        />
        <Component
          title='ACV'
          valueFormat={(v) => `$${d3.format('.1f')(v/1000)}`}
          showRate={true}
          periodLength={company.lastUpload.data.periodLength}
          headerRef={headerRef}
          pairs={bridgeData.map(d => {
            return {
              date: new Date(d.date),
              value: d.dollarsPerAll
            }
          })}
        />
        <Component
          title='Logos'
          valueFormat={(v) => parseInt(v)}
          showRate={true}
          periodLength={company.lastUpload.data.periodLength}
          headerRef={headerRef}
          pairs={bridgeData.map(d => {
            return {
              date: new Date(d.date),
              value: d.eopLogos
            }
          })}
        />
        <Component
          title='ARR'
          valueFormat={(v) => `$${d3.format('.1f')(v/1000000)}m`}
          showRate={true}
          periodLength={company.lastUpload.data.periodLength}
          headerRef={headerRef}
          pairs={bridgeData.map(d => {
            return {
              date: new Date(d.date),
              value: d.eop
            }
          })}
        />
        </>}
      </div>}
      
      {props.featureKey === 'download' && 
      <div className='w-full flex flex-wrap justify-center'>
        <a className='text-sm my-2 mr-5 flex items-center justify-center rounded-full px-5 py-2 font-semibold cursor-pointer text-blue-500 border border-blue-500' href={`${process.env.PUBLIC_URL}/files/vF-Download.xlsx`} download>Download vFinal generated Excel file</a>
        <a className='text-sm my-2 mr-5 flex items-center justify-center rounded-full px-5 py-2 font-semibold cursor-pointer text-gray-500 border border-gray-500' href={`${process.env.PUBLIC_URL}/files/vF-Input-File.csv`} download>Download original input file</a>
      </div>}
      
      {props.featureKey === 'bridge' && 
      <div className='w-full flex flex-wrap justify-center'>
        { bridgeData && groupedData &&
        <Component
            convertedData={groupedData}
            bridgeData={bridgeData}
            headerRef={headerRef}
          />
        }
      </div>}

      {props.featureKey === 'cohorts' && 
      <div className='w-full flex flex-wrap justify-center'>
        { bridgeData && groupedData &&
        <Component
          retType='net'
          data={groupedData}
          headerRef={headerRef}
          settings={company.settings}
          containerClassName={'w-full'}
          />
        }
      </div>}

      {props.featureKey === 'segmentation' && 
      <div className='w-full flex flex-wrap justify-center -mt-8 ml-12'>
        {company && company.lastUpload.data &&
        <Component
          company={company}
          convertedData={company.lastUpload.data}
          live={false}
          headerRef={headerRef}
          />
        }
      </div>}

      {props.featureKey === 'rollforward' && 
      <div className='w-full flex flex-wrap justify-center'>
        {headerRef && groupedData &&
        <Component
          inputData={groupedData}
          headerRef={headerRef}
          />
        }
      </div>}

      {props.featureKey === 'events' && 
      <div className='w-full flex flex-wrap justify-center'>
        {headerRef && groupedData &&
        <Component
          inputData={groupedData}
          rangeMonths={12}
          headerRef={headerRef}
          />
        }
      </div>}

      {props.featureKey === 'benchmarking' && 
      <div className='w-full flex flex-wrap justify-center ml-12'>
        {headerRef && groupedData &&
        <Component
          company={company}
          live={false}
          compsAvailable={compsAvailable}
          />
        }
      </div>}
    </div>
  )
}