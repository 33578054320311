import React, { useState, useRef, useEffect } from 'react';
import * as d3 from 'd3';
import { getTopEventsForPeriod } from '../../services/calculations';

export function BridgeExplorer(props) {
  const [dataColumns, setDataColumns] = useState([]);
  const [labelCells, setLabelCells] = useState([]);
  const [clicked, setClicked] = useState(false);
  const ref = useRef(null);

  const rowsMetadata = [
    {key: 'bop', label: 'Beginning of Period', dataType: 'integer'},
    {key: 'new', label: 'New', dataType: 'integer', labelClasses: 'pl-2'},
    {key: 'upsell', label: ' Upsell', dataType: 'integer', labelClasses: 'pl-2'},
    {key: 'downsell', label: ' Downsell', dataType: 'integer', labelClasses: 'pl-2'},
    {key: 'churn', label: ' Churn', dataType: 'integer', labelClasses: 'pl-2'},
    {key: 'reactivation', label: ' Reactivation', dataType: 'integer', labelClasses: 'pl-2'},
    {key: 'eop', label: 'End of Period', dataType: 'integer', classes: 'font-bold',},
    {key: 'LTMgross', label: 'Gross Retention (LTM)', dataType: 'percentage', classes: 'italic', labelClasses: 'pl-2', minMonthIndex: 12},
    {key: 'LTMnet', label: 'Net Retention (LTM)', dataType: 'percentage', classes: 'italic', labelClasses: 'pl-2', minMonthIndex: 12},
    {key: 'IPAgross', label: 'Gross Retention (IPA)', dataType: 'percentage', classes: 'italic', labelClasses: 'pl-2'},
    {key: 'IPAnet', label: 'Net Retention (IPA)', dataType: 'percentage', classes: 'italic', labelClasses: 'pl-2'},
    {key: 'blank1', label: '', dataType: 'blank'},
    {key: 'bopLogos', label: 'BoP Logos', dataType: 'integer'},
    {key: 'newLogos', label: 'New Logos', dataType: 'integer', labelClasses: 'pl-2'},
    {key: 'churnedLogos', label: 'Churned Logos', dataType: 'integer', labelClasses: 'pl-2'},
    {key: 'reactivatedLogos', label: 'Reactivated Logos', dataType: 'integer', labelClasses: 'pl-2'},
    {key: 'eopLogos', label: 'EoP Logos', dataType: 'integer', classes: 'font-bold'},
    {key: 'dollarsPerAll', label: 'Avg $ All Customers', dataType: 'integer', classes: 'italic', labelClasses: 'pl-2'},
    {key: 'dollarsPerNew', label: 'Avg $ New Customers', dataType: 'integer', classes: 'italic', labelClasses: 'pl-2'},
    {key: 'dollarsPerChurned', label: 'Avg $ Churned Customers', dataType: 'integer', classes: 'italic', labelClasses: 'pl-2'},
  ]

  let sections = ['new', 'upsell', 'downsell', 'churn', 'reactivation'];

  var metadataRef = {}
  for (let i=0; i<rowsMetadata.length; i++) {
    metadataRef[rowsMetadata[i].key] = rowsMetadata[i];
  }

  const handleColumnSelection = async (date) => {
    const topEvents = getTopEventsForPeriod(props.convertedData, date);
    let newLabelCells = labelCells.slice(0, rowsMetadata.length);

    // Data cells
    let newCols = [];
    for (let c=0; c<dataColumns.length; c++) {
      let newCells = dataColumns[c].slice(0, rowsMetadata.length+1);

      // Customer spacer cell
      if (c === 0) {
        newLabelCells.push(<div
          key={`customer-spacer`}
          className={`h-5 text-left text-xs w-48`}
          ></div>)
      } else {
        newCells.push(<div
          key={`customer-spacer`}
          className='h-5 text-right text-xs w-20'
          ></div>);
      }

      for (let s=0; s<sections.length; s++) {
        let sectionCustomers = topEvents[sections[s]];
        for (let sc=0; sc<Math.min(sectionCustomers.length, 5); sc++) {
          if (sc === 0) {
            newCells.push(<div
              key={`${sections[s]}-header`}
              className='h-5 text-right text-xs w-20 mt-2'
              ></div>);
            
            if (c === 0) {
              newLabelCells.push(<div
                key={`${sections[s]}-header`}
                className={`h-5 text-left text-xs w-48 font-bold mt-2`}
                >{sections[s].charAt(0).toUpperCase() + sections[s].slice(1)}</div>)
            }
          }

          newCells.push(<div
            key={`${c}-${sections[s]}-${sc}`}
            className='h-5 text-right text-xs w-20'
            >{getDisplayValue('integer', sectionCustomers[sc][0].values[c + sectionCustomers[sc][0].values.length-props.bridgeData.length])}</div>);
          
          if (c === 0) {
            newLabelCells.push(<div
              key={sectionCustomers[sc][0].keys.Customer}
              className={`pl-2 pr-2 h-5 text-left text-xs w-48 truncate`}
              >{sectionCustomers[sc][0].keys.Customer}</div>)
          }
        }
      }
      newCols.push(newCells);
    }

    setLabelCells(newLabelCells);
    setDataColumns(newCols);
  }
  
  useEffect(() => {
    if (props.bridgeData && props.bridgeData.length > 0) {
      let cols = [];
      let labelCells = [];
      for (let c=0; c<props.bridgeData.length; c++) {
        let cells = [];
        for (let r=0; r<rowsMetadata.length; r++) {
          let displayValue = 'NA';
          if (rowsMetadata[r].minMonthIndex === undefined || c >= rowsMetadata[r].minMonthIndex / props.convertedData.periodLength) {
            displayValue = getDisplayValue(rowsMetadata[r].dataType, props.bridgeData[c][rowsMetadata[r].key]);
          }

          if (r === 0) {
            cells.push(<div
              key={`date-${c}`}
              className={`h-5 text-center text-xs w-20 mb-2 font-bold`}
              >{props.headerRef[new Date(props.bridgeData[c].date)]}</div>);
          }

          cells.push(<div
            key={`${rowsMetadata[r].key}-${c}`}
            className={`h-5 text-right text-xs w-20 ${rowsMetadata[r].classes ? rowsMetadata[r].classes : ''}`}
            >{displayValue}</div>);
          
          if (c === 0) {
            let classes = [];
            classes += rowsMetadata[r].classes ? rowsMetadata[r].classes + ' ' : '';
            classes += rowsMetadata[r].labelClasses ? rowsMetadata[r].labelClasses + ' ' : '';
            labelCells.push(<div
              key={`${rowsMetadata[r].key}`}
              className={`h-5 text-left text-xs w-48 ${classes}`}
              >{rowsMetadata[r].label}</div>)
          }
        }
        cols.push(cells);
      }
      setLabelCells(labelCells);
      setDataColumns(cols);
    }
    
  }, [props.bridgeData]);

  useEffect(() => {
    if (!clicked) {
      ref.current.scrollLeft = ref.current.scrollWidth;
    }
    ref.current.classList.remove('hidden');
  }, [dataColumns]);


  function getDisplayValue(dataType, value) {
    if (dataType !== 'blank' && isNaN(value)) {
      return 'NA';
    }
    if (dataType === 'integer') {
      if (value >= 0) {
        return `${d3.format(",")(parseInt(value))} `;
      }
      return `(${d3.format(",")(Math.abs(parseInt(value)))})`;
      
    } else if (dataType === 'percentage') {
      return d3.format(".1%")(value);
    } else if (dataType === 'blank' || isNaN(value)) {
      return '';
    }
  }

  return (
    <div className='w-full tabular-nums mb-20'> 
      <div className='flex overflow-x-scroll w-full hidden' ref={ref}>
        <div className='sticky left-0 w-48 bg-white p-1'>
          <div key='blank-holder' className='h-5 w-48 mb-2'></div>
          { labelCells }
        </div>
        { dataColumns.map((columnCells, c) => {
          return <div
            key={`c-${c}`}
            className={'py-1 cursor-pointer hover:bg-gray-100'}
            onClick={() => {setClicked(true); handleColumnSelection(new Date(props.bridgeData[c].date));}}
            >
            {columnCells}
          </div>
        }) }
      </div>
    </div>
  );
}