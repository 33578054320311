import React, { useRef, useState } from 'react';

export function Tooltip(props) {
  const [styleState, setStyleState] = useState({display: 'none'});
  var tooltipRef = useRef(null);

  const showTooltip = (e) => {
    if (props.disabled) return;
    let rect = e.target.getBoundingClientRect();
    let width = 180;
    setStyleState({
      width: width,
      top: rect.top + rect.height + 5,
      left: (rect.left + rect.width/2)-width/2,
      display: null
    });
  }

  const hideTooptip = (e) => {
    setStyleState({
      display: 'none'
    });
  }

  let childEl = <></>;
  if (props.children) {
    let child = React.Children.only(props.children);
    childEl = React.cloneElement(
      child, 
      {
        onMouseEnter: showTooltip,
        onMouseLeave: hideTooptip
      }
    );
  }

  return (
    <>
      {childEl}
      <div ref={tooltipRef} className='bg-white opacity-50 p-2 mt-1 absolute text-xs border rounded text-center bg-gray-200' style={styleState}>{props.content}</div>
    </>
  );
}