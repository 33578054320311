import { createSlice } from "@reduxjs/toolkit";
export const mainSlice = createSlice({
  name: 'mainSlice',
  initialState: {
    data: undefined,
  },
  reducers: {
    processNewSourceFile: (state, action) => {
      state.inputData = action.payload.inputData;
      state.structuredInputData = action.payload.structuredInputData;
    }
  }
})

export const { processNewSourceFile } = mainSlice.actions;

export default mainSlice.reducer;