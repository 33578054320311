import React, { } from 'react';
import { useHistory } from 'react-router-dom';
import { daysUntilTrialExpiry, trialIsExpired } from '../../services/auth';

export function TrialWidget(props) {
  const history = useHistory();

  return (
    <>
    <div className='ml-20 text-xs text-gray-500 flex items-center font-normal'>
      {props.user && (!props.user.stripe || props.user.stripe && props.user.stripe.status !== 'paid') && trialIsExpired(props.user) && <>
        <div>Your trial has expired</div>
        <button
          type="button"
          onClick={() => history.push('/payment')}
          className="inline-flex items-center text-xs font-medium rounded text-indigo-700 hover:bg-indigo-50 ml-2 p-1.5"
        >
          Upgrade Now
        </button>
        </>
      }
      {props.user && (!props.user.stripe || props.user.stripe && props.user.stripe.status !== 'paid') && !trialIsExpired(props.user) && <>
        <div>{`Your trial is expiring in ${daysUntilTrialExpiry(props.user)}`}</div>
        <button
          type="button"
          onClick={() => history.push('/payment')}
          className="inline-flex items-center text-xs font-medium rounded text-indigo-700 hover:bg-indigo-50 ml-2 p-1.5"
        >
          Upgrade Now
        </button>
        </>
      }
    </div>
    </>
  );
}