import React, { useEffect } from 'react';
import * as d3 from 'd3';
import { useDispatch, useSelector } from 'react-redux';
import { getRecentCompanies } from '../data/companies/slice';
import { DateTime } from 'luxon';
import { useHistory } from 'react-router-dom';
import { DocumentAddIcon } from '@heroicons/react/outline';

const metricColumns = [
  {name: 'ARR', valueKey: 'eop', format: (v) => `$${d3.format('.1f')(v/1000000)}m`},
  {name: 'LTM Growth', valueKey: 'LTMgrowth', format: d3.format('0.0%')},
  {name: 'ACV', valueKey: 'dollarsPerAll', format: (v) => `$${d3.format('.1f')(v/1000)}k`},
  {name: '1-Year Net', valueKey: 'cohortRetNetMonth12', format: d3.format('0.0%')},
  {name: '1-Year Logo', valueKey: 'cohortRetLogoMonth12', format: d3.format('0.0%')},
]

function Companies() {
  const dispatch = useDispatch();
  const history = useHistory();
  const recentCompanies = useSelector((state) => state.companiesSlice.recentCompanies);
  const loggedIn = useSelector((state) => state.usersSlice.loggedIn);
  
  useEffect(() => {
    if (loggedIn) {
      dispatch(getRecentCompanies());
    } else if (loggedIn === false) {
      history.push('/login');
    }
  }, [loggedIn]);

  const formatMetric = (company, column) => {
    let value = company.metrics[column.valueKey];
    if (value !== undefined && !isNaN(value)) {
      return column.format(value);
    }
    return 'NA';
  }

  return (
    <div className='w-full pl-4'>
      {recentCompanies && recentCompanies.length === 0 && 
      <div className='w-96 mx-auto text-center'>
        <div className='text-2xl mb-10 mt-5'>You don't have any companies yet!</div>
        <button
            type='button'
            className='bg-indigo-700 text-white hover:bg-indigo-500 inline-flex items-center p-3 border border-transparent rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mr-5'
            onClick={() => history.push(`/upload`)}
          >
            <DocumentAddIcon className='h-5 w-5 mr-2' aria-hidden='true' /> Upload A Company
          </button>
      </div>}
      {recentCompanies && recentCompanies.length > 0 && <>
      <div className='text-2xl mb-10 mt-5'>Recent Companies</div>
      <div className='flex flex-col'>
      <div className='-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
        <div className='py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8'>
          <div className='shadow overflow-hidden border-b border-gray-200 sm:rounded-lg'>
            <table className='min-w-full divide-y divide-gray-200'>
              <thead className='bg-gray-50'>
                <tr>
                  <th
                    scope='col'
                    className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                  >
                    Name
                  </th>
                  <th
                    scope='col'
                    className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                  >
                    Time Created
                  </th>
                  <th
                    scope='col'
                    className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                  >
                    ARR Date
                  </th>
                  {metricColumns.map(col => {
                    return <th
                            key={col.name}
                            scope='col'
                            className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                          >
                            {col.name}
                          </th>
                  })}
                </tr>
              </thead>
              <tbody>
                {recentCompanies.map(c => {
                  return <tr
                            key={c._id}
                            className='hover:bg-gray-100 cursor-pointer'
                            onClick={() => history.push(`/company/dashboard?companyId=${c._id}`)}>
                    <td className='px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900'>{c.name}</td>
                    <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-500'>{DateTime.fromISO(c.timeCreated).toRelative()}</td>
                    <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-500'>{DateTime.fromISO(c.metrics.date).toFormat('LLL yyyy')}</td>
                    {metricColumns.map(col => {
                      return <td key={`${c._id}-${col.name}`} className='px-6 py-4 whitespace-nowrap text-sm text-gray-500'>{formatMetric(c, col)}</td>
                    })}
                  </tr>
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div></>}
    </div>
  );
}

export default Companies;
