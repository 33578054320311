import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import usersAPI from './api';
import posthog from 'posthog-js'
import * as Sentry from '@sentry/browser';

export const createStripeCheckoutSession = createAsyncThunk(
  'users/createStripeCheckoutSession',
  async (data, { rejectWithValue }) => {
    try {
      const response = await usersAPI.createStripeCheckoutSession(data);
      posthog.capture('create checkout', {plan: data.plan});
      return response.data;
    } catch (err) {
      Sentry.captureException(err);
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const createStripePortalSession = createAsyncThunk(
  'users/createStripePortalSession',
  async (_, { rejectWithValue }) => {
    try {
      const response = await usersAPI.createStripePortalSession();
      posthog.capture('create portal');
      return response.data;
    } catch (err) {
      Sentry.captureException(err);
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const paymentsSlice = createSlice({
  name: 'paymentsSlice',
  initialState: {},
  reducers: {},
});

export const { } = paymentsSlice.actions;

export default paymentsSlice.reducer;