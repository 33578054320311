import React, { useState } from 'react';
import SettingsModal from './SettingsModal';

export function SettingsWidget(props) {
  const [settingsModalOpen, setSettingsModalOpen] = useState(false);

  return (
    <>
      <button
        type='button'
        className={props.buttonClassName}
        onClick={() => setSettingsModalOpen(true)}
      >
        Settings
      </button>
      <SettingsModal
        open={settingsModalOpen}
        setOpen={setSettingsModalOpen}
        company={props.company}
        originalPeriodLength={props.originalPeriodLength}/>
    </>
  );
}