import { Fragment, useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Dialog, Transition } from '@headlessui/react';
import { createUserWithCompany, createUser } from '../../data/users/slice';

export default function SignupModal(props) {
  const dispatch = useDispatch();
  const teamNameInputRef = useRef(null);
  const [inputsState, setInputsState] = useState({});
  const companies = useSelector((state) => state.companiesSlice.companies);
  const [requestState, setRequestState] = useState('idle');
  const [errorMessage, setErrorMessage] = useState(null);
  const [modalConfig, setModalConfig] = useState({
    title: '',
    prompt: '',
    actionTitle: ''
  });

  const handleInputChange = (e) => {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    setInputsState({
      ...inputsState,
      [name]: value
    });
  }

  const handleSubmit = async () => {
    setRequestState('pending');
    setErrorMessage(null);
    try {
      let result;
      if (props.conversionMessage.action === 'save') {
        result = await dispatch(createUserWithCompany({
          ...inputsState,
          data: companies.upload.lastUpload.data,
          companyName: companies.upload.name,
          signupType: props.conversionMessage.action,
          conversion: {
            path: 'upload',
            plan: null
          }
        })).unwrap();
      } else if (props.conversionMessage.action === 'download' || props.conversionMessage.action === 'comps') {
        let data = {
          ...inputsState,
          signupType: props.conversionMessage.action,
          conversion: {
            path: props.conversionMessage.action,
            plan: null
          }
        }
        result = await dispatch(createUser(data)).unwrap();
      }
      setRequestState('fulfilled');
    } catch (e) {
      setRequestState('rejected');
      setErrorMessage(e.message);
    }
  }

  useEffect(() => {
    if (!props.conversionMessage) return;

    if (props.conversionMessage.permissionResult === 'signup required') {
      if (props.conversionMessage.action === 'save') {
        setModalConfig({
          title: 'Save to Library',
          prompt: <>Let's get you an account, then we can save this analysis to your library. It's free and takes 10 seconds!</>,
          actionTitle: 'Save'
        });
      } else if (props.conversionMessage.action === 'comps') {
        setModalConfig({
          title: 'Sign Up',
          prompt: <>You'll need an account to use the Comps feature.<br />It's free and takes 10 seconds!</>,
          actionTitle: 'Save'
        });
      } else if (props.conversionMessage.action === 'download') {
        setModalConfig({
          title: 'Sign Up',
          prompt: <>You'll need an account to download Excel backup files.<br />It's free and takes 10 seconds!</>,
          actionTitle: 'Save'
        });
      }
    }
  }, [props.conversionMessage]);

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={teamNameInputRef} onClose={props.close}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div className={requestState === 'fulfilled' ? 'hidden': ''}>
                <div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                      { modalConfig.title }
                    </Dialog.Title>
                    <div className="mt-2 mx-10">
                    <p className="text-sm text-gray-500 mt-3">{modalConfig.prompt}</p>
                    <div className="mt-6 relative border border-gray-300 rounded-md px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ring-indigo-600 focus-within:border-indigo-600">
                        <label
                          htmlFor="name"
                          className="absolute -top-2 left-2 -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-900"
                        >
                          Name Your Team
                        </label>
                        <input
                          type="text"
                          name="teamName"
                          value={inputsState.teamName || ''}
                          onChange={handleInputChange}
                          id="name"
                          className="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 outline-none sm:text-sm"
                          placeholder="My Company"
                          ref={teamNameInputRef}
                        />
                      </div>
                      <div className="mt-8 relative border border-gray-300 rounded-md px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ring-indigo-600 focus-within:border-indigo-600">
                        <label
                          htmlFor="name"
                          className="absolute -top-2 left-2 -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-900"
                        >
                          Your Name
                        </label>
                        <input
                          type="text"
                          name="userName"
                          value={inputsState.userName || ''}
                          onChange={handleInputChange}
                          id="name"
                          className="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 outline-none sm:text-sm"
                          placeholder="John Doe"
                        />
                      </div>
                      <div className="mt-6 relative border border-gray-300 rounded-md px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ring-indigo-600 focus-within:border-indigo-600">
                        <label
                          htmlFor="name"
                          className="absolute -top-2 left-2 -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-900"
                        >
                          Your Email
                        </label>
                        <input
                          type="email"
                          name="email"
                          autoCapitalize="none"
                          value={inputsState.email || ''}
                          onChange={handleInputChange}
                          id="name"
                          className="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 outline-none  sm:text-sm"
                          placeholder="you@company.com"
                        />
                      </div>
                    </div>
                    {errorMessage && <div className="text-red-700 text-sm mt-2 text-center">{errorMessage}</div>}
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                    onClick={requestState !== 'pending' ? handleSubmit : () => {}}
                  >
                    {requestState !== 'pending' ? modalConfig.actionTitle : 'Loading...'}
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                    onClick={() => props.close()}
                  >
                    Cancel
                  </button>
                </div>
              </div>
              <div className={requestState !== 'fulfilled' ? 'hidden': ''}>
                <div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                      Done!
                    </Dialog.Title>
                    <div className="mt-2 mx-10">
                      <p className="text-sm text-gray-500">
                        Check your email for a magic link!
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 ml-48 mr-48">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                    onClick={() => props.close()}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}