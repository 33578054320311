import React, { useState } from 'react';
import * as Sentry from "@sentry/react";
import { useDispatch, useSelector } from 'react-redux';
import { Route, useLocation, useHistory, Prompt } from 'react-router';
import { ClickToEditWidget } from '../../components/ClickToEditWidget';
import { ShareSaveWidget } from '../../components/ShareSaveWidget';
import { DeleteWidget } from '../../components/DeleteWidget';
import { getCompanyById, updateCompany, updateCompanyInLocalStore } from '../../data/companies/slice';

import Dashboard from './dashboard';
import Cohorts from './cohorts';
import Segmentation from './segmentation';
import Comps from './comps';
import { DownloadWidget } from '../../components/DownloadWidget';
import Customers from './customers';
import { SettingsWidget } from '../../components/SettingsWidget';
import { convertMonthsToQuarters, fillGaps, getPeriodHeaderRefFromInputData } from '../../services/calculations';

function App() {
  const user = useSelector((state) => state.usersSlice.user);
  const loggedIn = useSelector((state) => state.usersSlice.loggedIn);
  const companies = useSelector((state) => state.companiesSlice.companies);
  const getCompanyByIdPending = useSelector((state) => state.companiesSlice.getCompanyByIdPending);
  const dispatch = useDispatch();
  const history = useHistory();
  const search = useLocation().search;
  const pathname = useLocation().pathname;
  const baseButtonClasses = 'text-sm m-2 flex items-center justify-center rounded-full px-5 font-medium cursor-pointer';
  const activeButtonClasses = 'text-white bg-indigo-700';
  const inactiveButtonClasses = 'hover:bg-gray-300';
  const navButtons = ['Dashboard', 'Customers', 'Cohorts', 'Segmentation', 'Comps'];
  const [showDataConfirmation, setShowDataConfirmation] = useState(null);

  var parsedParams = new URLSearchParams(search);
  const companyId = parsedParams.get('companyId');
  var company = companies[companyId];
  var baseCompany = companies[companyId];
  if (company?.settings?.maxGapFixMonths) {
    let fixedData = fillGaps({...company.lastUpload.data}, company.settings.maxGapFixMonths);
    let upload = {...company.lastUpload, data: fixedData};
    company = {...company, lastUpload: upload};
    baseCompany = {...baseCompany, lastUpload: upload};
  }
  if (company?.settings?.convertToQuarters) {
    let quarterData = convertMonthsToQuarters({...company.lastUpload.data}, company.settings.fiscalYearEnd);
    let upload = {...company.lastUpload, data: quarterData};
    company = {...company, lastUpload: upload};
  }
  let headerRef = {};
  if (company) {
    headerRef = getPeriodHeaderRefFromInputData(company.lastUpload.data, company.settings);
  }
  
  if (loggedIn && user && !company && companyId && companyId != 'upload') {
    dispatch(getCompanyById(companyId));
  }

  if (loggedIn === false && companyId != 'upload') {
    history.push('/login');
  }

  const handleCompanyViewSelection = (view) => {
    history.push(`/company/${view.toLowerCase()}${search}`);
  }

  const isActive = (view) => {
    if (pathname.startsWith(`/company/${view.toLowerCase()}`)) {
      return true;
    }
    return false;
  }

  const handleCompanyNameUpdate = (newValue) => {
    let newCo = {...company, name: newValue};
    if (companyId === 'upload') {
      dispatch(updateCompanyInLocalStore(newCo));
    } else {
      dispatch(updateCompany(newCo));
    }
  }
  return (
    <Sentry.ErrorBoundary fallback={<div className='items-center mt-10 w-96 mx-auto'>Sorry, something went wrong. Please contact <a href='mailto:hello@vfinal.io'>hello@vfinal.io</a> with the source file you're using and we'll fix it ASAP.</div>}>
      <Prompt
        message={(location) => {
          return !(location.pathname.startsWith('/company') || location.search.includes('reupload=true')) && companyId === 'upload' ? 'You have not saved this company. Leave without saving?' : true;
        }}
      />
      {getCompanyByIdPending && <div className="flex justify-center items-center mt-10">
        <div
          className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900"
        ></div>
      </div>}
      {!getCompanyByIdPending && <>
      <div id='header-bottom' className='flex'>
        <ClickToEditWidget
          textClassName='m-2 flex items-center justify-center rounded-full pl-2 pr-10 font-medium cursor-pointer'
          currentValue={company ? company.name : 'Loading...'}
          placeholder='Unnamed Company'
          onUpdate={handleCompanyNameUpdate}
          tooltipDisabled={(company != null && company.name != null)}
          />
        { navButtons.map(view => {
          return <div
            key={view}
            onClick={() => handleCompanyViewSelection(view)}
            className={`${baseButtonClasses} ${isActive(view) ? activeButtonClasses : inactiveButtonClasses}`}>
              <div>{view}</div>
            </div>
        })}
        <div className='ml-auto flex'>
          <SettingsWidget
            company={company}
            originalPeriodLength={companies[companyId]?.lastUpload.data.periodLength}
            buttonClassName='text-sm my-2 mr-5 flex items-center justify-center rounded-full px-5 font-semibold cursor-pointer text-gray-500 border border-gray-500'
          />
          <DownloadWidget
            company={companies[companyId]}
            buttonClassName='text-sm my-2 mr-5 flex items-center justify-center rounded-full px-5 font-semibold cursor-pointer text-blue-500 border border-blue-500'
          />
          { companyId !== 'upload' &&
          <DeleteWidget
            company={company}
            buttonClassName='text-sm my-2 mr-5 flex items-center justify-center rounded-full px-5 font-semibold cursor-pointer text-red-500 border border-red-500'
          />
          }
          { companyId === 'upload' &&
          <ShareSaveWidget
            buttonClassName='text-sm my-2 mr-5 flex items-center justify-center rounded-full px-5 font-semibold cursor-pointer text-green-500 border border-green-500'
            action='Save to Library'
            signupType='save'
            prompt={<>Let's get you an account, then we can save this analysis to your library. It's free and takes 10 seconds!</>}
          /> }
          <ShareSaveWidget
            buttonClassName='hidden text-sm my-2 flex items-center justify-center rounded-full px-5 font-semibold cursor-pointer text-green-500 border border-green-500'
            action='Share'
            signupType='share'
            prompt={<>You'll need to create an account to share this analysis.<br/>It's free and takes 10 seconds!</>}
          />
        </div>
      </div>
      <div id='page-content'>
        <Route exact path='/company/dashboard'>
          <Dashboard company={baseCompany} convertedData={company?.lastUpload.data} headerRef={headerRef} showDataConfirmation={showDataConfirmation} setShowDataConfirmation={setShowDataConfirmation}/>
        </Route>
        <Route exact path='/company/customers'>
          <Customers company={baseCompany} convertedData={company?.lastUpload.data} headerRef={headerRef}/>
        </Route>
        <Route exact path='/company/cohorts'>
          <Cohorts company={baseCompany}/>
        </Route>
        <Route exact path='/company/segmentation'>
          <Segmentation company={baseCompany} convertedData={company?.lastUpload.data} headerRef={headerRef} live={true}/>
        </Route>
        <Route exact path='/company/comps'>
          <Comps company={baseCompany} live={true}/>
        </Route>
      </div>
      </>}
    </Sentry.ErrorBoundary>
  );
}

export default App;
