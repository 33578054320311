/* This example requires Tailwind CSS v2.0+ */

import { lazy, Suspense, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import postsMetadata from './post-list.json';
import posthog from 'posthog-js'

const postsRef = {};
postsMetadata.posts.map(p => postsRef[p.slug] = p);

// import Post from './posts/index.mdx';
export default function Blog() {
  const { slug } = useParams();
  const history = useHistory();
  let postMetadata = slug ? postsRef[slug] : undefined;
  var PostContent;
  if (slug && !postMetadata) {
    history.push('/404');
  } else if (slug && postMetadata) {
    PostContent = lazy(() => import(`./posts/${postMetadata.filename}`));
    document.title = postMetadata.seoTitle;
  } else {
    document.title = 'vFinal';
  }

  useEffect(() => {
    posthog.capture('view blog', {slug: slug});
  }, [slug]);

  return (
    <div className='bg-white'>
      <div className={'mx-auto mt-10 px-4 pb-28 sm:mt-16 sm:px-6 md:px-8 xl:px-12 lg:max-w-3xl xl:max-w-3xl'}>
        <div className='text-gray-700 sm:text-center mb-3 text-sm h-5'>{slug && `${postMetadata.datePublishedFormatted}`}</div>
        <div className={`col-span-full text-3xl font-extrabold tracking-tight sm:text-4xl sm:text-center mb-12 xs:mb-12 lg:mb-16 xl:mb-16 ${slug ? 'text-gray-900' : 'text-gray-900'}`}>{slug ? postMetadata.title : <span><span className='text-indigo-500'>v</span>Final Blog</span>}</div>
        <div className='max-w-prose mx-auto'>
          {!slug && 
            postsMetadata.posts.map(postMetadata => {
              return <article className="relative flex flex-col max-w-3xl lg:ml-auto xl:max-w-none xl:w-[50rem] mb-12" key={postMetadata.slug}>
                        <h3 onClick={() => {history.push(`/blog/${postMetadata.slug}`)}}
                          className="mb-4 text-xl text-gray-900 tracking-tight font-bold cursor-pointer">{postMetadata.title}</h3>
                        <div className="mb-6 prose">
                          <p>{postMetadata.description}</p>
                        </div>
                        <div className="mt-auto flex flex-row-reverse items-center justify-end">
                          <dl>
                              <dd className="text-sm leading-6 lg:absolute lg:top-0 lg:right-full lg:mr-8 lg:whitespace-nowrap">{postMetadata.datePublished}</dd>
                          </dl>
                          <svg width="2" height="2" fill="currentColor" className="mx-4 text-gray-700 lg:hidden">
                              <circle cx="1" cy="1" r="1"></circle>
                          </svg>
                          <button onClick={() => {history.push(`/blog/${postMetadata.slug}`)}}
                            className="group inline-flex items-center h-9 rounded-full text-sm font-semibold whitespace-nowrap px-3 focus:outline-none focus:ring-2 bg-gray-100 text-gray-700 hover:bg-gray-200 hover:text-gray-900 focus:ring-gray-500">
                            Read more
                            <svg className="overflow-visible ml-3 text-gray-300 group-hover:text-gray-400" width="3" height="6" viewBox="0 0 3 6" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                              <path d="M0 0L3 3L0 6"></path>
                            </svg>
                          </button>
                        </div>
                      </article>
            })}
            <Suspense fallback={<></>}>
            {slug && <article className='prose'>
              <PostContent />
              <div className='w-full text-center mx-auto mt-12 pt-8 border-t'>vFinal makes associates/VPs in VC/growth/buyout more successful. Auto-generate your bridges, cohort waterfalls, etc., all in 15 seconds and perfect every time.<br /><a className={'underline cursor-pointer text-indigo-600 hover:text-indigo-800'} href={`/?ref=blog-${slug}`}>See how it works</a> - it's free to try + no sign-up required.</div>
            </article>}
            </Suspense>
        </div>
      </div>
    </div>
  )
}
