import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import './index.css';
import App from './App';
import { Route, Switch } from 'react-router';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import posthog from 'posthog-js';
import store from './data/store';
import Marketing from './pages/marketing';
import { Onboarding } from './components/Onboarding';
import { Conversion } from './components/Conversion';

posthog.init('phc_VPNTc2j9yJAjDpQ2IaZfaS4I3xvtybaynpxxai7Ui9f', { api_host: 'https://app.posthog.com' });

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <Switch>
        <Route exact path='/'>
          <Marketing />
        </Route>
        <Route path='/blog'>
          <Marketing />
        </Route>
        <Route exact path='/platform'>
          <Marketing />
        </Route>
        <Route exact path='/pricing'>
          <Marketing />
        </Route>
        <Route exact path='/payment'>
          <Marketing />
        </Route>
        <Route exact path='/login'>
          <Marketing />
        </Route>
        <Route exact path='/signup'>
          <Marketing />
        </Route>
        <Route path='/'>
          <App />
          <Onboarding />
          <Conversion />
        </Route>
      </Switch>
    </BrowserRouter>
  </Provider>
  , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
