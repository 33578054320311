import * as d3 from 'd3';

const getQuarterIndexBoundsFromMonthlyData = (inputData, fye) => {
  let lastMonth = new Date(inputData.dates[inputData.dates.length-1]).getMonth() + 1;
  let lastIndex = inputData.dates.length - 1 - (lastMonth % 3 - fye % 3);
  if (lastIndex >= inputData.dates.length) {
    lastIndex -= 3;
  }
  let firstMonth = new Date(inputData.dates[0]).getMonth() + 1;
  let firstIndex = (3 - (firstMonth % 3 - fye % 3)) % 3;
  firstIndex = firstIndex - 2 < 0 ? firstIndex += 3 : firstIndex;
  return [firstIndex, lastIndex];
}

export const convertMonthsToQuarters = (inputData, fye) => {
  let quarterBounds = getQuarterIndexBoundsFromMonthlyData(inputData, fye);
  let quarterRows = [];
  let quarterDates = [];
  for (let r=0; r<inputData.rows.length; r++) {
    let quarterVals = [];
    let i = quarterBounds[0];
    while (i <= quarterBounds[1]-3) {
      quarterVals.push(inputData.rows[r].values[i+3]);
      if (r === 0) {
        quarterDates.push(inputData.dates[i+3]);
      }
      i += 3;
    }
    quarterRows.push({...inputData.rows[r], ...{values: quarterVals}});
  }
  return {...inputData, ...{rows: quarterRows, dates: quarterDates}, periodLength: 3};
}

export const getPeriodHeaderRefFromInputData = (inputData, settings={}) => {
  let dates = inputData.dates.map(d => new Date(d));
  let headerRef = {};
  if (inputData.periodLength === 1 && !settings.convertToQuarters) {
    dates.map(d => {
      headerRef[d] = d3.utcFormat("%b %Y")(d);
    });
  } else if (inputData.periodLength === 3 || settings.convertToQuarters) {
    let fye = settings.fiscalYearEnd;
    if (!fye) {
      fye = getDefaultFiscalYearEnd(inputData);
    }
    let prefix = fye === 12 ? 'CY' : 'FY';
    dates.map((d) => {
      let q = getFiscalQuarterFromDate(d, fye);
      let y = getFiscalYearFromDate(d, fye);
      headerRef[d] = `Q${q} ${prefix}${(y+'').slice(2)}`;
    });
  }
  return headerRef;
}

export const getDefaultFiscalYearEnd = (inputData) => {
  let lastMonth = new Date(inputData.dates[inputData.dates.length-1]).getMonth() + 1;
  let month = lastMonth % 3;
  return month === 0 ? 12 : month;
}

export const getFiscalYearFromDate = (date, fye) => {
  let y = date.getFullYear();
  let m = date.getMonth() + 1;
  return m <= fye ? y : y + 1;
}

export const getFiscalQuarterFromDate = (date, fye) => {
  let fm = getFiscalMonthFromDate(date, fye);
  return Math.ceil(fm/3);
}

export const getFiscalMonthFromDate = (date, fye) => {
  return (((date.getMonth() + 1) - fye) + 11) % 12 + 1;
}

const mergeRows = (r1, r2, groupBy, groupID) => {
  let mergedRow = {
    keys: {[groupBy]: groupID},
    values: r1.values.map((v,i) => v+r2.values[i])
  };

  return mergedRow;
}

export const groupInputData = (inputData, groupBy) => {
  // Takes structured input data
  let groupedRows = {};
  for (let r=0; r<inputData.rows.length; r++) {
    let groupID = inputData.rows[r].keys[groupBy];
    if (groupID in groupedRows) {
      groupedRows[groupID] = mergeRows(groupedRows[groupID], inputData.rows[r], groupBy, groupID);
    } else {
      groupedRows[groupID] = inputData.rows[r];
    }
  }
  return Object.assign({}, inputData, {rows: Object.values(groupedRows)});
}

export const segmentInputData = (inputData, key, maxNamedSegments=5) => {
  let currentARRBySegment = {};
  for (let r=0; r<inputData.rows.length; r++) {
    let name = inputData.rows[r].keys[key];
    let val = inputData.rows[r].values[inputData.rows[r].values.length-1];
    if (name in currentARRBySegment) {
      currentARRBySegment[name] += val;
    } else {
      currentARRBySegment[name] = val;
    }
  }
  
  // Find top "n" segments
  let segmentNames = Object.keys(currentARRBySegment);
  let namesAndVals = segmentNames.map(name => {return {name: name, val: currentARRBySegment[name]}});
  namesAndVals.sort((a, b) => b.val - a.val);
  let topSegmentNames = namesAndVals.slice(0,Math.min(namesAndVals.length, maxNamedSegments)).map(nv => nv.name);
  
  // Segment the rows
  let namedSegments = {};
  let otherSegment = [];
  topSegmentNames.map(name => namedSegments[name] = []);
  for (let r=0; r<inputData.rows.length; r++) {
    let name = inputData.rows[r].keys[key];
    if (name in namedSegments) {
      namedSegments[name].push(inputData.rows[r]);
    } else {
      otherSegment.push(inputData.rows[r]);
    }
  }

  if (otherSegment.length > 0) {
    namedSegments['[ Other ]'] = otherSegment;
  }

  return Object.keys(namedSegments).map(name => {
    let cleanName = name;
    if (name === 'undefined') {
      cleanName = 'All';
    } else if (name === '') {
      cleanName ='[ Blank ]';
    }
    return {
      name: cleanName,
      rows: namedSegments[name],
      dates: inputData.dates,
      periodLength: inputData.periodLength
    }
  });
}

export const annotateRowsWithStartIndices = (inputData) => {
  let rows = [...inputData.rows];
  for (let r=0; r<rows.length; r++) {
    let values = rows[r].values;
    for (let c=0; c<values.length; c++) {
      if (values[c] > 0) {
        rows[r] = Object.assign({}, rows[r], {startIndex: c});
        break;
      }
    }
  }
  rows = rows.filter(r => r.startIndex != undefined && Number.isInteger(r.startIndex));
  return {...inputData, rows: rows};
}

export const generateBridgeData = (inputDataConst, groupBy=null, fye=12, convertToQuarters=false) => {
  // Takes structured input data from file processing output
  let inputData = Object.assign({}, inputDataConst);

  // Group
  if (groupBy) {
    inputData = groupInputData(inputData, groupBy);
  }

  // Build bridge
  inputData = annotateRowsWithStartIndices(inputData);
  let bridgeData = [];
  for (let c=1; c<inputData.dates.length; c++) {
    let data = {
      date: inputData.dates[c],
      bop: 0,
      eop: 0,
      new: 0,
      upsell: 0,
      downsell: 0,
      churn: 0,
      reactivation: 0,
      blank1: '',
      bopLogos: 0,
      newLogos: 0,
      churnedLogos: 0,
      reactivatedLogos: 0,
      eopLogos: 0,
      dollarsPerAll: undefined,
      dollarsPerNew: undefined,
      dollarsPerChurned: undefined
    }

    for (let r=0; r<inputData.rows.length; r++) {
      if (isNaN(inputData.rows[r].values[c-1])) continue;
      data.bop += inputData.rows[r].values[c-1];
      data.eop += inputData.rows[r].values[c];
      if (inputData.rows[r].values[c-1] > 0) {
        data.bopLogos += 1;
      }
      if (inputData.rows[r].values[c] > 0) {
        data.eopLogos +=1;
      }
      if (inputData.rows[r].values[c-1] === 0 && inputData.rows[r].values[c] > 0) {
        if (c === inputData.rows[r].startIndex) {
          data.new += inputData.rows[r].values[c];
          data.newLogos += 1;
        } else {
          data.reactivation += inputData.rows[r].values[c];
          data.reactivatedLogos += 1;
        }
      }
      else if (inputData.rows[r].values[c-1] > 0 && inputData.rows[r].values[c] === 0) {
        data.churn -= inputData.rows[r].values[c-1];
        data.churnedLogos -= 1;
      }
      else if (inputData.rows[r].values[c-1] > 0 && inputData.rows[r].values[c] > 0) {
        if (inputData.rows[r].values[c-1] < inputData.rows[r].values[c]) {
          data.upsell += inputData.rows[r].values[c] - inputData.rows[r].values[c-1];
        } else if (inputData.rows[r].values[c-1] > inputData.rows[r].values[c]) {
          data.downsell -= inputData.rows[r].values[c-1] - inputData.rows[r].values[c];
        }
      }
    }
    bridgeData.push(data);
  }

  if (convertToQuarters) {
    bridgeData = convertMonthlyBridgeToQuarterly(bridgeData, inputData, fye);
  }

  for (let c=0; c<bridgeData.length; c++) {
    if (bridgeData[c].eopLogos > 0) {
      bridgeData[c].dollarsPerAll = bridgeData[c].eop / bridgeData[c].eopLogos;
    }
    if (bridgeData[c].newLogos > 0) {
      bridgeData[c].dollarsPerNew = bridgeData[c].new / bridgeData[c].newLogos;
    }
    if (bridgeData[c].churnedLogos < 0) {
      bridgeData[c].dollarsPerChurned = bridgeData[c].churn / bridgeData[c].churnedLogos;
    }
  }

  return annotateBridgeWithRetention(bridgeData, convertToQuarters ? 3 : inputData.periodLength);
}

export const convertMonthlyBridgeToQuarterly = (bridgeData, inputData, fye) => {
  const qBounds = getQuarterIndexBoundsFromMonthlyData(inputData, fye);
  const bopBalanceItems = ['bop', 'bopLogos'];
  const eopBalanceItems = ['eop', 'eopLogos'];
  const flowItems = ['new', 'upsell', 'downsell', 'churn', 'reactivation', 'newLogos', 'churnedLogos', 'reactivatedLogos'];
  let qBridgeData = [];
  for (let i=qBounds[0]+2; i<=qBounds[1]; i += 3) {
    let q = {date: bridgeData[i].date};
    for (let bi=0; bi<bopBalanceItems.length; bi++) {
      let key = bopBalanceItems[bi];
      q[key] = bridgeData[i-2][key];
    }
    for (let bi=0; bi<eopBalanceItems.length; bi++) {
      let key = eopBalanceItems[bi];
      q[key] = bridgeData[i][key];
    }
    for (let fi=0; fi<flowItems.length; fi++) {
      let key = flowItems[fi];
      q[key] = bridgeData.slice(i-2, i+1).reduce((total, column) => total + column[key],0);
    }
    qBridgeData.push(q);
  }
  return qBridgeData;
}

export const annotateBridgeWithRetention = (bridgeData, periodLength) => {
  let lookback = 12 / periodLength - 1;
  for (let i=0; i<bridgeData.length; i++) {
    bridgeData[i].IPAgross = (1 + ((bridgeData[i].churn + bridgeData[i].downsell) / bridgeData[i].bop)) ** (12/periodLength);
    bridgeData[i].IPAnet = (1 + ((bridgeData[i].churn + bridgeData[i].downsell + bridgeData[i].upsell) / bridgeData[i].bop)) ** (12/periodLength);
    if (i>lookback) {
      bridgeData[i].LTMgrowth = bridgeData[i].eop / bridgeData[i-lookback].eop - 1;
      // LTM calc
      let upsellSum = 0;
      let churnDownsellSum = 0;
      for (let j=i-lookback; j<=i; j++) {
        upsellSum += bridgeData[j].upsell;
        churnDownsellSum += bridgeData[j].churn + bridgeData[j].downsell;
      }
      bridgeData[i].LTMgross = 1 + (churnDownsellSum / bridgeData[i-lookback].bop);
      bridgeData[i].LTMnet = 1 + ((churnDownsellSum + upsellSum) / bridgeData[i-lookback].bop);
    } else {
      bridgeData[i].LTMgross = '';
      bridgeData[i].LTMnet = '';
    }
  }
  return bridgeData;
}

export const getTopEventsForPeriod = (inputData, date) => {
  let c = inputData.dates.findIndex(d => new Date(d).getTime() === date.getTime());
  let events = {
    new: [],
    upsell: [],
    downsell: [],
    churn: [],
    reactivation: []
  }
  for (let r=0; r<inputData.rows.length; r++) {
    if (inputData.rows[r].values[c] > 0 && inputData.rows[r].values[c-1] === 0) {
      if (inputData.rows[r].startIndex === c) {
        events.new.push([inputData.rows[r], inputData.rows[r].values[c], [c]]);
      } else {
        events.reactivation.push([inputData.rows[r], inputData.rows[r].values[c], [c]]);
      }
    } else if (inputData.rows[r].values[c] > 0 && inputData.rows[r].values[c-1] > 0 && inputData.rows[r].values) {
      if (inputData.rows[r].values[c] > inputData.rows[r].values[c-1]) {
        events.upsell.push([inputData.rows[r], inputData.rows[r].values[c]-inputData.rows[r].values[c-1], [c]]);
      } else if (inputData.rows[r].values[c] < inputData.rows[r].values[c-1]) {
        events.downsell.push([inputData.rows[r], inputData.rows[r].values[c]-inputData.rows[r].values[c-1], [c]])
      }
    } else if (inputData.rows[r].values[c-1] > 0 && inputData.rows[r].values[c] === 0) {
      events.churn.push([inputData.rows[r], -inputData.rows[r].values[c-1], [c]])
    }
  }
  events.new.sort((a,b) => b[1] - a[1]);
  events.upsell.sort((a,b) => b[1] - a[1]);
  events.downsell.sort((a,b) => a[1] - b[1]);
  events.churn.sort((a,b) => a[1] - b[1]);
  events.reactivation.sort((a,b) => b[1] - a[1]);
  return events;
}

export const getTopEventsInIndexRange = (inputData, i1, i2, limitPer=5) => {
  let topEvents = {
    new: [],
    upsell: [],
    downsell: [],
    churn: [],
    reactivation: []
  }
  for (let i=i1; i<i2; i++) {
    let topEventsInPeriod = getTopEventsForPeriod(inputData, new Date(inputData.dates[i]));
    for (const category in topEvents) {
      let periodEvents = topEventsInPeriod[category];
      let numEvents = Math.min(periodEvents.length, limitPer);
      topEvents[category].push(...periodEvents.slice(0, numEvents));
      if (category === 'downsell' || category === 'churn') {
        topEvents[category].sort((a,b) => a[1] - b[1]);
      } else {
        topEvents[category].sort((a,b) => b[1] - a[1]);
      }
      topEvents[category] = topEvents[category].slice(0, Math.min(topEvents[category].length, limitPer));
    }
  }

  // Merge customers into single lines if they account for multiple top events
  for (const category in topEvents) {
    let customers = topEvents[category];
    let customerRef = {};
    for (let c=0; c<customers.length; c++) {
      let name = customers[c][0].keys.Customer;
      if (name in customerRef) {
        customerRef[name][2] = [...customerRef[name][2], ...customers[c][2]];
      } else {
        customerRef[name] = customers[c];
      }
    }
    topEvents[category] = Object.values(customerRef);
  }
  return topEvents;
}

////// WATERFALLS //////

export const generateWaterfallCells = (data, retType='net', settings={}) => {
  let firstMonth;
  let firstIndex;
  if (settings.convertToQuarters) {
    firstMonth = new Date(data.dates[0]).getMonth() + 1;
    firstIndex = (3 - (firstMonth % 3 - settings.fiscalYearEnd % 3)) % 3;
  }
  let dataGrouped = groupInputData(data, 'Customer');
  let dataWSI = annotateRowsWithStartIndices(dataGrouped);
  let groupedRows = {};
  for (let r=0; r<dataWSI.rows.length; r++) {
    let startIndex = parseInt(dataWSI.rows[r].startIndex);
    let cohortIndex = startIndex;
    if (settings.convertToQuarters) {
      cohortIndex = Math.ceil((cohortIndex - firstIndex) / 3) * 3 + firstIndex;
    }

    let values = [];
    for (let v=startIndex; v<dataWSI.rows[r].values.length; v++) {
      if (settings.convertToQuarters && (v-startIndex) % 3 !== 0) {
        continue;
      }
      let value = dataWSI.rows[r].values[v];
      if (retType === 'logo') {
        value = value > 0 ? 1 : 0;
      }
      values.push(value);
    }
    if (cohortIndex in groupedRows) {
      groupedRows[cohortIndex].push(values);
    } else {
      groupedRows[cohortIndex] = [values];
    }
  }

  let triples = [];
  let cohortIndices = Object.keys(groupedRows).map(i => parseInt(i));
  for (let g=Math.min(...cohortIndices); g<Math.max(...cohortIndices)+1; g++) {
    if (g in groupedRows) {
      if (g >= dataWSI.dates.length) {
        continue;
      }
      for (let v=0; v<groupedRows[g][0].length; v++) {
        triples.push({
          cohortDate: new Date(dataWSI.dates[g]),
          periodNumber: v,
          value: groupedRows[g].reduce((pV, cV) => pV + cV[v], 0)
        })
      }
    } else if (settings.convertToQuarters && g % 3 !== firstIndex) {
      continue;
    } else {
      triples.push({
        cohortDate: new Date(dataWSI.dates[g]),
        periodNumber: 0,
        value: 0
      })
    }
  }
  return convertInputsToWaterfallCells(triples);
}

const convertInputsToWaterfallCells = (triples) => {
  const selectedSegment = triples.sort((a, b) => d3.ascending(a.cohortDate, b.cohortDate) || d3.ascending(a.periodDate, b.periodDate));
  const userCounts = d3.rollup(selectedSegment, ([{value}]) => value, d => d.cohortDate);
  const cohortDates = new d3.InternSet(selectedSegment.map(d => d.cohortDate));
  const cohortDatesArray = Array.from(cohortDates).sort((a,b) => a.getTime() - b.getTime());
  const periodNumbers = new d3.InternSet(selectedSegment.map(d => d.periodNumber));
  
  // Make sure we have a user count for every combination of cohort date and period date, and calculate
  // period numbers and percentages for each entry.
  const cross = d3.cross(cohortDates, periodNumbers, (cohortDate, periodNumber) => ({cohortDate, periodNumber}))
    .filter(({cohortDate, periodNumber}) => cohortDatesArray.indexOf(cohortDate) + periodNumber < cohortDatesArray.length );

  const rollup = d3.rollup(selectedSegment, ([{value}]) => value, d => d.cohortDate, d => d.periodNumber);
  const data = cross.map(({cohortDate, periodNumber}) => {
    const value = (rollup.has(cohortDate) && rollup.get(cohortDate).has(periodNumber))
      ? rollup.get(cohortDate).get(periodNumber)
      : 0;
    const percentage = value / userCounts.get(cohortDate);
    return {cohortDate, periodNumber, value, percentage};
  });
  
  return data.sort((a, b) => d3.ascending(a.cohortDate, b.cohortDate) || d3.ascending(a.periodNumber, b.periodNumber));
}

export const fillGaps = (data, maxGapFixMonths) => {
  let maxGapFix = maxGapFixMonths;
  if (data.periodLength === 3) {
    if (maxGapFix < 3) {
      return company;
    } else {
      maxGapFix = 1;
    }
  }

  let fixedRows = [];
  for (let r=0; r<data.rows.length; r++) {
    let v=0;
    let values = [...data.rows[r].values];
    while (v<data.rows[r].values.length) {
      if (values[v] > 0) {
        let nonZeroEndIndex;
        let foundBlank = false;
        for (let sv=Math.min(values.length-1, v+maxGapFix+1); sv>v; sv--) {
          if (nonZeroEndIndex !== undefined && values[sv] === 0) {
            // Look for blanks, but only if we've already found a nonZeroEndIndex
            foundBlank = true;
          } else if (nonZeroEndIndex === undefined && values[sv] !== 0) {
            nonZeroEndIndex = sv;
          }
          if (foundBlank && nonZeroEndIndex !== undefined) {
            break;
          }
        }
        if (foundBlank && nonZeroEndIndex !== undefined) {
          // Found a blank in the range and a non-zero end index, now replace all zeros, to where the end of the gap was found
          let startVal = values[v];
          v += 1;
          while (v<nonZeroEndIndex) {
            if (values[v] === 0) {
              values[v] = startVal;
            }
            v += 1;
          }
        } else {
          // No zeros or no end index, so move to the next section of values
          v += maxGapFix + 1;
        }
      } else {
        v +=1;
      }
    }
    fixedRows.push({...data.rows[r], values: values});
  }

  return {...data, rows: fixedRows};
}

export const getCohortCurvePoints = (data, retType='net') => {
  let cells = generateWaterfallCells(data, retType);
  const startARRValues = Array.from(d3.rollup(cells, ([{value}]) => value, d => d.cohortDate).values());
  const sumProducts = d3.rollup(cells, v => d3.sum(v, d => d.value), d => d.periodNumber);
  let points = [];
  sumProducts.forEach((sumProduct, periodNumber) => points.push(sumProduct / d3.sum(startARRValues.slice(0,startARRValues.length-periodNumber))));
  return points.map((p, i) => {return {x: i*data.periodLength, y: p}});
}

export const getMetricsForCompany = (company) => {
  let metrics = {...company.bridgeData[company.bridgeData.length-1]};
  let year1Index = 12 / company.lastUpload.data.periodLength;
  let year2Index = 24 / company.lastUpload.data.periodLength;
  if (company.netPoints.length > year1Index && company.logoPoints.length > year1Index) {
    metrics.cohortRetNetMonth12 = company.netPoints[year1Index].y;
    metrics.cohortRetLogoMonth12 = company.logoPoints[year1Index].y;
  } if (company.netPoints.length > year1Index && company.logoPoints.length > year1Index) {
    metrics.cohortRetNetMonth24 = company.netPoints[year2Index].y;
    metrics.cohortRetLogoMonth24 = company.logoPoints[year2Index].y;
  }
  
  for (const [k,v] of Object.entries(metrics)) {
    if (v !== undefined && v !== null && typeof(v) !== 'string' && !isNaN(v) && v !== 0 && !Number.isInteger(v)) {
      metrics[k] = Math.round(v*100000)/100000;
    }
  }
  return metrics;
}