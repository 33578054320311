import React from 'react';
import { CohortGrid } from '../../components/CohortGrid';
import posthog from 'posthog-js'

function Cohorts(props) {
  if (props.company) {
    posthog.capture('view cohorts', {companyId: props.company._id});
  }
  
  return (
    <div className='w-full'>
      <div className='text-2xl mb-10 mt-5 w-full'>Waterfalls</div>
      <div className='w-full flex flex-wrap'>
        { props.company && props.company.lastUpload && props.company.lastUpload.data && <>
        <CohortGrid
          retType='net'
          data={props.company.lastUpload.data}
          headerRef={props.headerRef}
          settings={props.company.settings}
          containerClassName={'w-full'}
        />
        </>}
        { props.company && props.company.lastUpload && props.company.lastUpload.data && <>
        <CohortGrid
          retType='logo'
          data={props.company.lastUpload.data}
          headerRef={props.headerRef}
          settings={props.company.settings}
          containerClassName={'w-full mt-10'}
        />
        </>}
      </div>
    </div>
  );
}

export default Cohorts;
