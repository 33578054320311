import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/outline';

const planDetail = {
  associate: {
    message: 'You now have access to the features on the Associate plan.',
    features: [
      'Click "Download" to get the formula backup file for any company',
      'Keep using all the automated analytics you already have'
    ]
  },
  partner: {
    message: 'You now have access to the features on the Partner plan.',
    features: [
      'Use the Comps feature to benchmark companies across anything in your library',
      'Click "Download" to get the formula backup file for any company',
      'Keep using all the automated analytics you already have'
    ]
  }
}

export default function OnboardingModal(props) {
  
  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={props.setOpen}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div>
                <div>
                  {props.signupType !== 'invite' && props.signupType !== 'paymentSuccess' &&
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                      Signup complete!
                    </Dialog.Title>
                    {(props.signupType === 'save' || props.signupType === 'share')&& 
                    <div className="mt-2 mx-10">
                      <p className="text-sm text-gray-500">
                        We saved your company to your library.
                      </p>
                      {props.signupType === 'share' &&
                      <p className="text-sm text-gray-500">
                        To share this company, use this link:
                      </p>
                      }
                    </div>}
                    {props.signupType === 'basic' && 
                    <div className="mt-2 mx-10">
                      <p className="text-sm text-gray-500">
                        Welcome to vFinal
                      </p>
                    </div>}
                    {props.signupType === 'download' && 
                    <div className="mt-2 mx-10">
                      <p className="text-sm text-gray-500">
                        You can now download companies.<br/>Upload your company again to try it out!
                      </p>
                    </div>}
                    {props.signupType === 'comps' && 
                    <div className="mt-2 mx-10">
                      <p className="text-sm text-gray-500">
                        You can now use the Comps feature.<br/>Upload and save a few companies to try it out!
                      </p>
                    </div>}
                  </div>}
                  {props.signupType === 'invite' &&
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                      You've joined your team on vFinal!
                    </Dialog.Title>
                    <div className="mt-2 mx-10">
                      <p className="text-sm text-gray-500">
                        You can now see companies your team has uploaded and upload new companies to your team's library.
                      </p>
                    </div>
                  </div>}
                  {props.signupType === 'paymentSuccess' &&
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                      Payment submitted successfully!
                    </Dialog.Title>
                    <div className="mt-4 mx-10">
                      <p className="text-sm text-gray-500 mb-2 mt-2">
                        {planDetail[props.plan].message}
                      </p>
                      <ul role="list" className="space-y-4 text-sm mt-6 text-left">
                      {planDetail[props.plan].features.map((feature) => (
                        <li key={feature} className="flex items-start">
                          <div className="flex-shrink-0">
                            <CheckIcon className="h-6 w-6 text-green-500" aria-hidden="true" />
                          </div>
                          <p className="ml-3 text-sm text-gray-700">{feature}</p>
                        </li>
                      ))}
                    </ul>
                    </div>
                  </div>}
                </div>
                <div className="mt-5 sm:mt-6 ml-48 mr-48">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                    onClick={() => props.setOpen(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}