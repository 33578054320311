import React, { useState } from 'react';
import { UserAddIcon } from '@heroicons/react/outline';

import InviteUserModal from './InviteUserModal';
import { useDispatch } from 'react-redux';

export function InviteUserWidget(props) {
  const [inviteModalOpen, setInviteModalOpen] = useState(false);

  return (
    <>
      <button
        type='button'
        className={props.buttonClassName}
        onClick={() => setInviteModalOpen(true)}
      >
        <UserAddIcon className='h-5 w-5 mr-2' aria-hidden='true' /> Invite User
      </button>
      <InviteUserModal
        open={inviteModalOpen}
        setOpen={setInviteModalOpen}/>
    </>
  );
}