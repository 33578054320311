import React from 'react';
import LogRocket from 'logrocket';
import { Route, Switch } from 'react-router';
import { useHistory } from 'react-router-dom';
import { DocumentAddIcon, LoginIcon, LogoutIcon, UserAddIcon } from '@heroicons/react/outline';
import './App.css';

import Upload from './pages/upload';
import Company from './pages/company';
import Companies from './pages/companies';
import { SearchWidget } from './components/SearchWidget';
import { InviteUserWidget } from './components/InviteUserWidget';
import { useSelector } from 'react-redux';
import { TrialWidget } from './components/TrialWidget';

if (process.env.NODE_ENV === 'production') {
  LogRocket.init('pvydaf/vfinal');
}

function App() {
  const history = useHistory();
  const user = useSelector((state) => state.usersSlice.user);
  const loggedIn = useSelector((state) => state.usersSlice.loggedIn);

  return (
    <>
    <div id='header-top-container' className='w-full'>
      <div id='header-top' className='grid grid-cols-3'>
        <div className='col-span-1 flex items-center text-lg font-bold pl-4'>
          <span className='cursor-pointer' onClick={() => loggedIn === true ? history.push('/companies') : history.push('/')}><span className='text-indigo-500'>v</span>Final</span>
          {user && <TrialWidget user={user} />}
        </div>
        <div className='col-span-1 flex items-center text-lg font-bold'>
          <SearchWidget
            containerClassName={`${loggedIn !== true ? 'hidden' : ''} relative max-w-lg w-full lg:max-w-xs mx-auto`}
            handleSelection={(c) => history.push(`/company/dashboard?companyId=${c._id}`)}
            placeholder={'Search Companies'}
            live={true} />
        </div>
        <div className='col-span-1 flex items-center justify-end'>
          <button
            type='button'
            className='text-sm hover:bg-gray-200 inline-flex items-center p-1 border border-transparent rounded-full text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mr-5'
            onClick={() => history.push(`/upload`)}
          >
            <DocumentAddIcon className='h-5 w-5 mr-2' aria-hidden='true' /> New Upload
          </button>
          { loggedIn === true && user && <>
              <InviteUserWidget buttonClassName={'text-sm hover:bg-gray-200 inline-flex items-center p-1 border border-transparent rounded-full text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mr-5'}/>
              <button
                type='button'
                className='text-sm hover:bg-gray-200 inline-flex items-center p-1 border border-transparent rounded-full text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mr-5'
                onClick={() => window.location=`${process.env.REACT_APP_API_URL}/users/logout`}
              > <LogoutIcon className='h-5 w-5 mr-2' aria-hidden='true' /> Log Out
              </button>
            </>
          }
          { !loggedIn && <>
              <button
                type='button'
                className='text-sm hover:bg-gray-200 inline-flex items-center p-1 border border-transparent rounded-full text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mr-5'
                onClick={() => history.push('/login')}
              >
                <LoginIcon className='h-5 w-5 mr-2' aria-hidden='true' /> Log In
              </button>
              <button
                type='button'
                className='text-sm hover:bg-gray-200 inline-flex items-center p-1 border border-transparent rounded-full text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mr-5'
                onClick={() => history.push('/signup')}
              >
                <UserAddIcon className='h-5 w-5 mr-2' aria-hidden='true' /> Sign Up
              </button>
            </>
          }
          </div>
      </div>
    </div>
    <div id='main-container'> 
      <Switch>
        <Route exact path='/upload'>
          <Upload />
        </Route>
        <Route path='/company'>
          <Company />
        </Route>
        <Route exact path='/companies'>
          <Companies />
        </Route>
      </Switch>
    </div>
    </>
  );
}

export default App;
