import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { clearLastUploadedId, createCompanyWithUpload, setShowUploadSuccess } from '../../data/companies/slice';
import { checkPermissionWithMessage } from '../../services/auth';

import Notification from '../Notification';
import { Tooltip } from '../Tooltip';

export function ShareSaveWidget(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const lastUploadedId = useSelector((state) => state.companiesSlice.lastUploadedId);
  const showUploadSuccess = useSelector((state) => state.companiesSlice.showUploadSuccess);
  const user = useSelector((state) => state.usersSlice.user);
  var parsedParams = new URLSearchParams(useLocation().search);
  var pathname = useLocation().pathname;
  const createCompanyWithUploadPending = useSelector((state) => state.companiesSlice.createCompanyWithUploadPending);
  const uploadCompany = useSelector((state) => state.companiesSlice.companies.upload);

  const handleActionStart = () => {
    if (!createCompanyWithUploadPending) {
      let allowed = checkPermissionWithMessage(user, 'save');
      if (allowed) {
        dispatch(createCompanyWithUpload());
      }
    }
  }
  
  const [buttonConfig, setButtonConfig] = useState({
    tooltipContent: '',
    tooltipDisabled: true,
    onClick: handleActionStart
  });

  useEffect(() => {
    if (props.action === 'Save to Library' && lastUploadedId && !createCompanyWithUploadPending) {
      // Replace "companyId=upload" in URL with real companyId after saving company, then flip to that URL
      parsedParams.delete('companyId');
      parsedParams.append('companyId', lastUploadedId);
      history.push(`${pathname}?${parsedParams.toString()}`);
    }
  }, [lastUploadedId, createCompanyWithUploadPending]);

  if (props.action === 'Save to Library' && lastUploadedId && parsedParams.get('companyId') !== 'upload') {
    // We've now replaced "upload" with the real ID in the URL after the redirect, but lastUploadedId is still set, so clear it
    dispatch(clearLastUploadedId());
  }

  useEffect(() => {
    if (uploadCompany) {
      let isUploadedNotSaved = parsedParams.get('companyId') && parsedParams.get('companyId') === 'upload';
      if (isUploadedNotSaved && uploadCompany.name === null) {
        if (props.action === 'Save to Library') {
          setButtonConfig({
            tooltipDisabled: false,
            onClick: () => {},
            tooltipContent: 'Name your company first. Click the name to edit it.'
          });
        } else if (props.action === 'Share') {
          setButtonConfig({
            tooltipDisabled: false,
            onClick: () => {},
            tooltipContent: 'Name and save your company first. Click the name to edit it.'
          });
        }
      } else if (isUploadedNotSaved && uploadCompany.name !== null) {
        if (props.action === 'Save to Library') {
          setButtonConfig({
            tooltipDisabled: true,
            onClick: handleActionStart,
            tooltipContent: ''
          });
        } else if (props.action === 'Share') {
          setButtonConfig({
            tooltipDisabled: false,
            onClick: () => {},
            tooltipContent: 'Save your company first.'
          });
        }
      }
    }
  }, [user, uploadCompany]);
  
  if (parsedParams.get('companyId') !== 'upload' && buttonConfig.tooltipDisabled === false) {
    setButtonConfig({
      tooltipDisabled: true,
      onClick: handleActionStart,
      tooltipContent: ''
    });
  }
  
  return (
    <>
      <Notification
        title='Company saved successfully!'
        detail='You can now share this company and find it in your library.'
        open={showUploadSuccess}
        setOpen={(v) => dispatch(setShowUploadSuccess(v))} />
      <Tooltip content={buttonConfig.tooltipContent} disabled={buttonConfig.tooltipDisabled}>
        <div onClick={buttonConfig.onClick} className={props.buttonClassName}>{props.action}</div>
      </Tooltip>
    </>
  );
}