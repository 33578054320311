import { CheckIcon } from '@heroicons/react/solid';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { sendLoginRequest, createUser } from '../data/users/slice';

function LoginSignup() {
  const teamNameInputRef = useRef(null);
  const emailInputRef = useRef(null);
  const [inputsState, setInputsState] = useState({});
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [requestState, setRequestState] = useState('idle');
  const [errorMessage, setErrorMessage] = useState(null);
  const action = location.pathname === '/login' ? 'login' : 'signup';
  const loggedIn = useSelector((state) => state.usersSlice.loggedIn);
  const parsedParams = new URLSearchParams(location.search);
  
  if (loggedIn) {
    history.push('/companies');
  }

  useEffect(() => {
    if (action === 'login') {
      emailInputRef.current.focus();
    } else {
      teamNameInputRef.current.focus();
    }
  }, []);

  const handleInputChange = (e) => {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    setInputsState({
      ...inputsState,
      [name]: value
    });
  }

  const handleSubmit = async () => {
    setRequestState('pending');
    setErrorMessage(null);
    try {
      let result;
      if (action === 'login') {
        result = await dispatch(sendLoginRequest({email: inputsState.email})).unwrap();
      } else {
        let data = {
          ...inputsState,
          conversion: {
            path: parsedParams.get('path'),
            plan: parsedParams.get('plan')
          }
        }
        result = await dispatch(createUser(data)).unwrap();
      }
      setRequestState('fulfilled');
    } catch (e) {
      setRequestState('rejected');
      setErrorMessage(e.message);
    }
  }

  return (
    <>
      <div className='w-96 mx-auto'>
      <div className='text-3xl font-bold mx-auto text-center mt-32 mb-10'><span className='text-indigo-500'>v</span>Final</div>
        <div className={`${requestState === 'fulfilled' ? 'hidden' : ''}`}>
          <div className='text-center'>
            <div className='mx-10'>
             <div className={`${action === 'login' ? 'hidden' : ''} mt-8 relative border border-gray-300 rounded-md px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ring-indigo-600 focus-within:border-indigo-600`}>
                <label
                  htmlFor='name'
                  className='absolute -top-2 left-2 -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-900'
                >
                  Name Your Team
                </label>
                <input
                  type='text'
                  name='teamName'
                  value={inputsState.teamName || ''}
                  onChange={handleInputChange}
                  id='name'
                  className='block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 outline-none sm:text-sm'
                  placeholder='My Company'
                  ref={teamNameInputRef}
                />
              </div>
              <div className={`${action === 'login' ? 'hidden' : ''} mt-8 relative border border-gray-300 rounded-md px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ring-indigo-600 focus-within:border-indigo-600`}>
                <label
                  htmlFor='name'
                  className='absolute -top-2 left-2 -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-900'
                >
                  Your Name
                </label>
                <input
                  type='text'
                  name='userName'
                  value={inputsState.userName || ''}
                  onChange={handleInputChange}
                  id='name'
                  className='block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 outline-none sm:text-sm'
                  placeholder='John Doe'
                />
              </div>
              <div className='mt-6 relative border border-gray-300 rounded-md px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ring-indigo-600 focus-within:border-indigo-600'>
                <label
                  htmlFor='name'
                  className='absolute -top-2 left-2 -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-900'
                >
                  Your Email
                </label>
                <input
                  type='email'
                  name='email'
                  autoCapitalize='none'
                  value={inputsState.email || ''}
                  onChange={handleInputChange}
                  onKeyPress={(e) => {if (e.key === 'Enter') { handleSubmit() }}}
                  id='name'
                  className='block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 outline-none  sm:text-sm'
                  placeholder='you@company.com'
                  ref={emailInputRef}
                />
              </div>
            </div>
            {errorMessage && <div className='text-red-700 text-sm mt-2 text-center'>{errorMessage}</div>}
          </div>
          <div className='mt-5 w-48 mx-auto'>
            <button
              type='button'
              className='w-48 rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
              onClick={requestState !== 'pending' ? handleSubmit : () => {}}
            >
              {requestState !== 'pending' ? (action === 'login' ? 'Log In' : 'Sign Up') : 'Loading...'}
            </button>
          </div>
        </div>
      </div>
      <div className={`${requestState !== 'fulfilled' ? 'hidden' : ''}`}>
        <div className='mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100'>
          <CheckIcon className='h-6 w-6 text-green-600' aria-hidden='true' />
        </div>
        <div className='mx-auto flex items-center justify-center'>
          <div className='mt-2'>
            <p className='text-sm text-gray-500'>
              Check your email for a magic link!
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default LoginSignup;
