import { Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { createStripePortalSession } from '../../data/payments/slice';

export default function PaymentModal(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [modalConfig, setModalConfig] = useState({
    title: '',
    prompt: '',
    actionTitle: ''
  });

  const handleAction = async () => {
    if (props.conversionMessage.permissionResult === 'plan required') {
      history.push('/payment');
    } else if (props.conversionMessage.permissionResult === 'upgrade required') {
      setLoading(true);
      let session = await dispatch(createStripePortalSession()).unwrap();
      setLoading(false);
      window.location = session.url;
    }
  }

  useEffect(() => {
    if (!props.conversionMessage) return;

    if (props.conversionMessage.permissionResult === 'plan required') {
      if (props.conversionMessage.action === 'comps') {
        setModalConfig({
          title: 'Trial Expired',
          prompt: <>You'll need to upgrade to the Partner<br />plan to use the Comps feature.</>,
          actionTitle: 'Choose Plan'
        });
      } else if (props.conversionMessage.action === 'download') {
        setModalConfig({
          title: 'Trial Expired',
          prompt: <>You'll need to upgrade to a paid plan<br />to download Excel backup files.</>,
          actionTitle: 'Choose Plan'
        });
      }
    } else if (props.conversionMessage.permissionResult === 'upgrade required') {
      if (props.conversionMessage.action === 'comps') {
        setModalConfig({
          title: 'Upgrade Required',
          prompt: <>The Comps feature is only available on the Partner plan. You'll need to upgrade to access it.</>,
          actionTitle: 'Upgrade Now'
        });
      }
    }

  }, [props.conversionMessage]);

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={props.close}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div>
                <div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                      { modalConfig.title }
                    </Dialog.Title>
                    <div className="mt-2 mx-10">
                      <p className="text-sm text-gray-500 mt-3">{modalConfig.prompt}</p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                    onClick={handleAction}
                  >
                    {loading ? 'Loading...' : modalConfig.actionTitle}
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                    onClick={() => props.close()}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}