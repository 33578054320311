import * as d3 from 'd3';

export const LineChartCellSVG = (pairs, {
  width = 300,
  height = 174,
  setHoverData = undefined,
  maxBoundY = null,
  minBoundY = null,
  headerRef = {},
} = {}) => {
  const margin = {top: 10, right: 30, bottom: 30, left: 30};

  const xVals = d3.map(pairs, (t) => t.date);
  const xValsUnique = Array.from(new Set(xVals)).sort((a, b) => a.getTime() - b.getTime());

  const x = d3.scaleTime()
    .domain(d3.extent(pairs, d => d.date))
    .range([margin.left, width - margin.right])

  let yVals = pairs.map(d => d.value);
  let extent = d3.extent(yVals.filter(v => (typeof(v) === 'number' && !isNaN(v) && Math.abs(v) !== Infinity))).reverse();
  let maxY;
  let minY;
  if (maxBoundY) {
    maxY = Math.min(extent[0], parseInt(maxBoundY));
  } else {
    maxY = extent[0];
  }
  
  if (minBoundY) {
    minY = Math.max(extent[1], parseInt(minBoundY));
  } else {
    minY = extent[1];
  }
  
  const y = d3.scaleLinear()
    .domain([maxY, minY])
    .range([margin.top, height - margin.bottom])
  
  const div = d3.create('div');
  
  const svg = div.append('svg')
    .attr('viewBox', [0, 0, width, height])
    .attr('width', width)
    .attr('height', height);

  svg.append('path')
    .datum(pairs)
    .attr('fill', 'none')
    .attr('stroke', 'steelblue')
    .attr('stroke-width', 1.5)
    .attr('d', d3.line()
    .defined(d => (typeof(d.value) === 'number' && !isNaN(d.value) && Math.abs(d.value) !== Infinity))
      .x(d => x(d.date))
      .y(d => y(d.value))
      )

  svg.append('line')
    .attr('stroke', '#AAA')
    .style('stroke-width', 1)
    .attr('x1', margin.left)
    .attr('x2', width-margin.right)
    .attr('y1', height-margin.bottom+10)
    .attr('y2', height-margin.bottom+10)

  svg.append('text')
    .attr('x', margin.left)
    .attr('y', height - margin.bottom + 25)
    .attr('text-anchor', 'start')
    .style('font-size', '10px')
    .text(headerRef[pairs[0].date])

  svg.append('text')
    .attr('x', width - margin.right)
    .attr('y', height - margin.bottom + 25)
    .attr('text-anchor', 'end')
    .style('font-size', '10px')
    .text(headerRef[pairs[pairs.length-1].date])
  
  svg.append('rect')
    .attr('class', 'hover-line')
    .attr('x', 0)
    .attr('y', margin.top)
    .attr('height', height-margin.top-margin.bottom)
    .attr('width', 2)
    .attr('opacity', 0)
    .attr('fill', 'black')
  
  // Hover line & catch rect

  svg.append('rect')
    .attr('class', 'hover-line')
    .attr('x', 0)
    .attr('y', margin.top)
    .attr('height', height - margin.bottom - margin.top)
    .attr('width', 2)
    .attr('opacity', 0)
    .attr('fill', 'black')
    
  const catchRect = svg.append('rect')
    .attr('x', 0)
    .attr('y', 0)
    .attr('height', height)
    .attr('width', width)
    .style('fill', '#FFF')
    .attr('opacity', 0)
    .on('mousemove', hoverMove)

  function getRelPos(event, rect, rectMargin) {
    if (event.clientX === 0 || event.clientY === 0) {
      return null // Sometimes we get bad click data w/ click pos x0y0
    }
    return {
      x: event.clientX - rect.left - rectMargin.left,
      y: event.clientY - rect.top - rectMargin.top
    }
  }

  function hoverMove(e) {
    let rect = catchRect.node().getBoundingClientRect();
    let pos = getRelPos(e, rect, margin);
    if (pos) {
      if (rect.width > 0 && rect.height > 0 && (pos.x < 0 || pos.y < 0 || pos.x > rect.width-margin.left-margin.right || pos.y > rect.height-margin.top-margin.bottom)) {
        svg.select('.hover-line')
          .attr('opacity', 0);
        setHoverData({date: null, value: null}, null);
      } else if (rect.width > 0 && rect.height > 0) {
        let i = Math.round(xValsUnique.length * pos.x / (width-margin.left-margin.right)) - 1;
        if (i < xValsUnique.length && i >= 0) {
          svg.select('.hover-line')
            .attr('x', pos.x + margin.left)
            .attr('opacity', 0.5);
          setHoverData(pairs[i], i);
        }
      }
    }
  }
    
  const element = div.node();
  element.value = null;
  return element;
}